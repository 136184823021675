



































































































































import { User } from "@/models/User";
import Vue from "vue";
import PostComponent from "../../components/Post.vue";
import { PostRepository, UserRepository } from "@/repositories";
import { Post } from "@/models/Post";
import { mapGetters } from "vuex";
import EditProfileButton from "@/views/components/EditProfileButton.vue";
import {
  DIRECTMESSAGE,
  FOLLOW,
  UNFOLLOW,
  OPENPROFILESITE,
} from "@/core/services/store/analytics.module";

export default Vue.extend({
  components: { PostComponent, EditProfileButton },

  data: () => ({
    loadingPosts: true,
    logoutMessage: false,
    hideProfile: false,
    profileUser: new User(),
    profilePosts: new Array<Post>(),
    isSelf: false,
    isSpecialist: false,
  }),
  computed: {
    ...mapGetters(["currentUser"]),
  },
  watch: {
    "$route.params.userName": function () {
      this.loadPage();
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    infiniteHandler($state) {
      let lastVisible = null;

      if (this.profilePosts.length > 0) {
        lastVisible = this.profilePosts[this.profilePosts.length - 1].postRef;
      }

      const userName = this.$route.params.userName;

      PostRepository.getPostsFromUserName(userName, lastVisible).then(
        (r: Array<Post>) => {
          if (r.length > 0) {
            this.profilePosts.push(...r);
            $state.loaded();
          } else {
            $state.complete();
          }
        }
      );
    },
    loadPage() {
      const userName = this.$route.params.userName;

      if (userName == this.currentUser.userName) {
        this.isSelf = true;
      } else {
        this.isSelf = false;
      }

      if (this.isSelf) {
        this.profileUser = this.currentUser;
      } else {
        UserRepository.getByUsername(userName).then((user: User | null) => {
          if (user) {
            this.profileUser = user;
            this.profileUser.checkFollowed().then((isFollowed) => {
              this.profileUser.isFollowed = isFollowed;
            });

            if (this.profileUser.roles) {
              this.isSpecialist = this.profileUser.roles.some(
                (role) => role == "Specialist"
              );
            }
          } else {
            this.$router.replace({ name: "Feed" });
          }
        });
      }
    },
    logout() {
      this.$store.dispatch("logout");
    },
    sendMessage() {
      if (this.profileUser.phoneNumber) {
        const number =
          "55" +
          this.profileUser.phoneNumber
            .replace("(", "")
            .replace(")", "")
            .replace(" ", "")
            .replace("-", "");
        const whatsappUrl =
          "api.whatsapp.com/send?phone=" +
          number +
          "&text=%20" +
          "Peguei seu contato pela boutSex";

        window.open("https://" + whatsappUrl, "_blank");
        this.$store.dispatch(DIRECTMESSAGE, { userId: this.currentUser.id });
      }
    },
    openSite(page) {
      this.$store.dispatch(OPENPROFILESITE, {
        userId: this.currentUser.id,
        page: page,
      });
      window.open("https://" + page, "_blank");
    },
    follow() {
      if (this.profileUser.userName) {
        this.$store.dispatch(FOLLOW, { userId: this.currentUser.id });
        this.profileUser.follow();
      }
    },

    unfollow() {
      if (this.profileUser.userName) {
        this.$store.dispatch(UNFOLLOW, { userId: this.currentUser.id });
        this.profileUser.unfollow();
      }
    },

    async updateProfile() {
      this.hideProfile = true;
      const userName = this.$route.params.userName;
      await UserRepository.getByUsername(userName)
        .then((user: User | null) => {
          if (user) {
            this.profileUser = user;

            this.profileUser.checkFollowed().then((isFollowed) => {
              this.profileUser.isFollowed = isFollowed;
            });
          } else {
            this.$router.replace({ name: "Feed" });
          }
        })
        .finally(() => {
          this.hideProfile = false;
        });
    },
  },
});
