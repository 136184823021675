import { EroticTalesRepository } from "@/repositories";
import { User } from "./User";
import firebaseMain from "firebase";

export class EroticTales {
    constructor() { }
    private _id: string = "";
    public get id(): string {
        return this._id;
    }

    private _userRef: string = "";
    public get userRef(): string {
        return this._userRef;
    }
    public set userRef(value: string) {
        this._userRef = value;
    }

    private _media: Media = new Media();
    public get media(): Media {
        return this._media;
    }

    private _title: string = "";
    public get title(): string {
        return this._title;
    }

    private _content: string = "";
    public get content(): string {
        return this._content;
    }

    private _createdDate: Date = new Date();
    public get createdDate(): Date {
        return this._createdDate;
    }

    private _tags: Array<string> = [];
    public get tags(): Array<string> {
        return this._tags;
    }
    public set tags(value: Array<string>) {
        this._tags = value;
    }

    private _creatorUser: UserVModel = new UserVModel();
    public get creatorUser(): UserVModel {
        return this._creatorUser;
    }
    public set creatorUser(value: UserVModel) {
        this._creatorUser = value;
    }

    private _counters: Counters = new Counters();
    public get counters(): Counters {
        return this._counters;
    }
    public set counters(value: Counters) {
        this._counters = value;
    }

    private _isLiked: boolean = false;
    public get isLiked(): boolean {
        return this._isLiked;
    }
    public set isLiked(value: boolean) {
        this._isLiked = value;
    }

    private _isVoted: number | null = -1;
    public get isVoted(): number | null {
        return this._isVoted;
    }
    public set isVoted(value: number | null) {
        this._isVoted = value;
    }

    private _deleted: boolean = false;
    public get deleted(): boolean {
        return this._deleted;
    }
    public set deleted(value: boolean) {
        this._deleted = value;
    }

    private _postRef: firebaseMain.firestore.QueryDocumentSnapshot<
        firebaseMain.firestore.DocumentData
    >;
    public get postRef(): firebaseMain.firestore.QueryDocumentSnapshot<
        firebaseMain.firestore.DocumentData
    > {
        return this._postRef;
    }
    public set postRef(
        value: firebaseMain.firestore.QueryDocumentSnapshot<
            firebaseMain.firestore.DocumentData
        >
    ) {
        this._postRef = value;
    }

    static factory = {
        create(post: any) {
            let newPost = new EroticTales();

            newPost._id = post.id;
            newPost._title = post.title;
            newPost._content = post.content;
            newPost._counters = post.counters;
            newPost._createdDate = post.createdDate;
            newPost._media = post.media;
            newPost._tags = post.tags;
            newPost._creatorUser = post.creatorUser;

            return newPost;
        },
        createFromDB(
            post: firebase.default.firestore.DocumentData,
            postId: string,
            ref
        ) {
            let newPost = new EroticTales();

            newPost._id = postId;
            newPost._title = post.title;
            newPost._content = post.content;
            newPost._counters = post.counters;
            newPost._createdDate = post.createdDate;
            newPost._media = post.media;
            newPost._tags = post.tags;
            newPost._creatorUser = post.creatorUser;
            newPost._postRef = ref;

            return newPost;
        },
    };

    public create(title: string, content: string, media?: IMedia) {
        return 2;
    }

    edit(title: string, content: string) { }

    delete() {
        return EroticTalesRepository.delete(this);
    }

    like() {
        this.counters.likes = this.counters.likes + 1;

        return EroticTalesRepository.like(this)
            .then(() => {
                this._isLiked = true;
            })
            .catch(() => {
                this.counters.likes = this.counters.likes - 1;
            });
    }

    unlike() {
        if (this.counters.likes > 0) {
            this.counters.likes = this.counters.likes - 1;
        }

        return EroticTalesRepository.unlike(this).then(() => {
            this._isLiked = false;
        });
    }

    vote(index: number) {

        if (this.isVoted < 0) {

            const optionItem = JSON.parse(JSON.stringify(this.media.mediaItems[index]))
            optionItem.option.votes = parseInt(optionItem.option.votes) + 1
            this.media.mediaItems[index] = optionItem

            return EroticTalesRepository.vote(this, index)
                .then(() => {
                    this._isVoted = index;
                })
                .catch(() => {
                    // this.counters.likes = this.counters.likes - 1;
                });
        } else {
            return Promise.reject('Enquete já votada')
        }
    }
    increaseCommentsCounters() {
        this.counters.comments = this.counters.comments + 1;

        return EroticTalesRepository.updateCommentsCounter(this, +1).catch(() => {
            this.counters.comments = this.counters.comments - 1;
        });
    }

    decreaseCommentsCounters(number: number) {
        if (this.counters.comments > 0) {
            this.counters.comments = this.counters.comments - number;
        }

        return EroticTalesRepository.updateCommentsCounter(this, -number);
    }

    publish(user: User, media) {
        const rolesArray = Object.values(user.roles) as Array<string>;

        if (rolesArray.some((role) => role == "Specialist")) {
            if (media.type === 'Survey') {
                this._media.type = "Survey";
                return EroticTalesRepository.publishSurvey(this);
            } else if (media.length > 1) {
                this._media.type = "Carousel";
            } else if (media.type === 'Tweet') {
                this._media.type = "Tweet"
                return EroticTalesRepository.publishTweet(this);
            } else {
                switch (media[0].type.split("/")[0]) {
                    case "video":
                        this._media.type = "Video";
                        break;
                    case "image":
                        this._media.type = "Photo";
                        break;
                }
            }

            return EroticTalesRepository.publish(user, this, media);
        } else {
            return Promise.reject("Usuário não é um especialista");
        }
    }
}
// export class PostVModel {
//     constructor() { }

//     id: string = "";
//     createdDate: Date = new Date();
//     title: string = "";
//     content: string = "";
//     tags: Array<string> = [];
//     isLiked: boolean = false;

//     creatorUser: UserVModel = new UserVModel();
//     counters: Counters = new Counters();
//     media: Media = new Media();

//     static factory = {
//         /// Create a new user instance from PostVModel
//         createFromPost(post: Post){
//             let newPost = new PostVModel();

//             newPost.id = post.id
//             newPost.title = post.title
//             newPost.content = post.content
//             newPost.counters = post.counters
//             newPost.createdDate = post.createdDate
//             newPost.media = post.media
//             newPost.tags = post.tags
//             newPost.creatorUser = post.creatorUser

//             return newPost;
//         },
//     }
// }

export class UserVModel {
    displayName: string = "";
    userName: string = "";
    avatar: string = "";
}

export class Media implements IMedia {
    type: "Photo" | "Video" | "Survey" | "Carousel" | 'Tweet' = "Photo";
    url: string = "";
    metadata: any;
    fileName: string = "";
    mediaItems: Array<object>;
}

export interface IMedia {
    type: "Photo" | "Video" | "Survey" | "Carousel" | 'Tweet';
    url: string;
    metadata: any;
}

export class Counters {
    constructor() { }

    public likes: number = 0;
    public comments: number = 0;
    public views: number = 0;

    public increaseLikes() {
        this.likes++;
    }

    public decreaseLikes() {
        this.likes--;
    }

    public increaseComments() {
        this.comments++;
    }

    public decreaseComments() {
        this.comments--;
    }
}
