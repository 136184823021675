<template>
  <div>
    <v-sheet color="#F08301">
      <v-row class="background" align="center" no-gutters>
        <v-col
          order="last"
          order-sm="first"
          class="pb-0"
          cols="12"
          lg="6"
          sm="12"
        >
        </v-col>
        <v-col
          order="first"
          order-sm="last"
          class="text-col pt-8 pb-8"
          cols="12"
          lg="6"
          sm="12"
        >
          <v-card elevation="24" class="login-card">
            <v-form class="login">
              <v-img
                width="300"
                class="mx-auto mb-4"
                src="../../../assets/logotipo_RED.png"
              ></v-img>
              <!-- <h1 class="text-center mb-8">Entrar</h1> -->

              <v-text-field
                hidden-details
                filled
                color="secondary"
                type="text"
                label="Email"
                v-model="email"
                :hint="errors.emailError"
                :error="errors.emailError != ''"
                persistent-hint
              />
              <v-text-field
                filled
                :value="senha"
                :type="value ? 'text' : 'password'"
                color="secondary"
                label="Senha"
                v-model="senha"
                :hint="errors.passwordError"
                :error="errors.passwordError !== ''"
                :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (value = !value)"
                persistent-hint
              />
              <v-row justify="center" class="pa-8 pt-2">
                <v-btn
                  large
                  color="primary"
                  :loading="loading"
                  @click="signInWithEmailAndPassword"
                  >Entrar</v-btn
                >
              </v-row>
              <p class="caption text-center mt-2">_ Ou entre com _</p>
              <v-row justify="center">
                <v-btn
                  color="indigo"
                  class="ma-4"
                  large
                  icon
                  outlined
                  @click="signInWithFacebook"
                  ><v-icon>mdi-facebook</v-icon></v-btn
                >
                <v-btn
                  color="blue"
                  class="ma-4"
                  large
                  icon
                  outlined
                  @click="signInWithGoogle"
                  ><v-icon>mdi-google</v-icon></v-btn
                >
              </v-row>
              <v-row justify="center" class="mt-12">
                <v-btn outlined to="/registrar">
                  Não tem conta? Crie uma aqui
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>

    <v-sheet>
      <v-row no-gutters>
        <v-col>
          <h1 class="pb-8 pt-8 text-center red-title">
            Tire suas dúvidas sobre sexo anonimamente com uma profissional
          </h1>
          <p class="text-center body-2">
            A boutSEX veio pra te colocar em contato com os melhores sexólogos
            do Brasil. E isso tudo em segredo.
          </p>
          <p class="text-center body-2">
            A boutSEX é mais que perguntas. Imagine um Instagram que te coloca
            em contato direto com sexólogos. Anônimo, moderado e grátis.
          </p>

          <p class="text-center body-2">
            Cadastre-se e receba milhares de conteúdos, participe de discussões,
            enquetes e muito mais.
          </p>
        </v-col>
      </v-row>
    </v-sheet>

    <v-sheet class="pb-4">
      <h1 class="text-center pt-8 red-title">
        Experimente nossa rede sem compromisso
      </h1>
      <v-row class="items-row" justify="center" no-gutters>
        <v-col v-for="(item, i) in items" :key="i" cols="12" lg="3" sm="12">
          <v-card flat class="text-center card-items">
            <v-img width="100" :src="item.image"></v-img>
            <v-card-title v-text="item.title"> </v-card-title>
            <v-card-text v-text="item.text"></v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet class="pb-12">
      <h1 class="text-center pt-8 red-title">
        Conheça alguns de nossos conteúdos
      </h1>
      <v-row class="items-row mt-8" justify="center" no-gutters>
        <v-col
          v-for="(postItem, i) in postItems"
          :key="i"
          cols="12"
          lg="3"
          sm="12"
        >
          <post
            hidePostFollow
            previewMode
            :post="postItem"
            @click.native="goTo(postItem.id)"
          />
        </v-col>
      </v-row>
    </v-sheet>
    <v-footer color="secondary" class="pt-12 pb-12">
      <v-row align="center" class="footer-row">
        <v-col cols="12"
          ><v-img
            class="mx-auto"
            width="150"
            src="../../../assets/logotipo-boutsex_WHITE.png"
          ></v-img
        ></v-col>
        <v-col cols="12" class="text-col"
          ><span class="caption"
            >Ao se cadastrar você autoriza a boutSEX a armazenar seus dados de
            maneira segura.</span
          ></v-col
        >
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import firebase from "firebase";
import { mapGetters } from "vuex";
import { PostRepository } from "@/repositories";
import Post from "@/views/components/Post.vue";

export default {
  components: { Post },
  name: "login",

  data() {
    return {
      items: [
        {
          image: require("../../../assets/sex.png"),
          title: "Descubra novos temas sobre sexo",
          text: "Acha que sabe tudo? Não sabe nada? Quer descobrir coisas novas? Explore o conteúdo.",
        },
        {
          image: require("../../../assets/incognito.png"),
          title: "Opção de esconder sua identidade",
          text: "Navegação pelo conteúdo e perguntas feitas de maneira anônima se preferir.",
        },
        {
          image: require("../../../assets/specialist.png"),
          title: "Lista de sexólogos",
          text: "Encontre facilmente os melhores sexólogos do Brasil, conheça seu trabalho e siga o que preferir.",
        },
      ],
      postIds: [
        "yM94Qj0AMqxlW5vSV5fO",
        "Lb00mmErmQdlO1wYNgH4",
        "c73VeQEuVKjQpC7z03Eu",
        // "znWCrpcwuWmljhpDau1z",
        // "Jrar3iH0IyzBXxzCbG9C",
        // "yjh9iaNJ5gAww0gFypiR",
      ],
      postItems: [],
      value: false,
      loading: false,
      loadingPosts: false,
      email: "",
      senha: "",
      errors: {
        emailError: "",
        passwordError: "",
      },
    };
  },
  mounted() {
    this.getPosts();
  },
  computed: {
    ...mapGetters(["authError"]),
  },
  watch: {
    authError(val) {
      switch (val) {
        case "auth/invalid-email":
          this.errors.emailError = "Email inválido";
          break;
        case "auth/wrong-password":
          this.errors.emailError = "";
          this.errors.passwordError = "Insira sua senha";
          break;
        case "auth/user-not-found":
          this.errors.passwordError = "Senha e email não conferem";
          break;
        default:
          this.errors = {
            emailError: "",
            passwordError: "",
          };
          break;
      }
    },
  },
  methods: {
    goTo(id) {
      this.$router.push({ name: "Postagem", params: { postId: id } });
    },
    getPosts() {
      this.loadingPosts = true;
      for (const postId of this.postIds) {
        PostRepository.getPost(postId)
          .then((post) => {
            if (post) {
              this.postItems.push(post);
            } else {
              this.postItems.push(false);
            }
          })
          .finally(() => {
            this.loadingPosts = false;
          });
      }
    },
    signInWithFacebook() {
      const provider = new firebase.auth.FacebookAuthProvider();

      this.$store.dispatch("socialLogin", provider);
    },
    signInWithGoogle() {
      const provider = new firebase.auth.GoogleAuthProvider();

      this.$store.dispatch("socialLogin", provider);
    },
    async signInWithEmailAndPassword() {
      this.loading = true;
      await this.$store.dispatch("login", {
        email: this.email,
        password: this.senha,
      });
      this.loading = false;
      console.log(this.authError);
    },
  },
};
</script>

<style scoped lang="scss">
.login-card {
  border-radius: 20px !important;
  .login {
    padding: 2rem;
  }
}

.background {
  background-image: url("../../../assets/background.png");
  background-size: 70%;
  background-position: bottom left;
  background-position-x: 13rem;
  padding: 0 10rem 0 10rem;
  height: 40rem;
}
.text-col {
  color: white;
  text-align: center !important;
}
.items-row {
  .card-items {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.red-title {
  color: var(--v-primary-base);
}
.footer-row {
  justify-content: center;
}
@media (max-width: 600px) {
  .background {
    height: auto;
    background-size: contain;
    background-position-x: 1rem;
    padding: 0 1rem 10rem 1rem;
  }
}
</style>