<template>
  <v-app-bar
    app
    bottom
    v-if="isAuthenticated"
    :dark="isAnonymous"
    :key="isSpecialist"
  >
    <v-snackbar v-model="display" :timeout="2001">
      <p class="text-center mb-0">
        {{ message }}
      </p>
    </v-snackbar>
    <v-spacer></v-spacer>

    <v-card
      flat
      class="ma-1"
      height="56"
      width="80"
      @click="() => this.$router.push('/feed')"
    >
      <v-row dense justify="center" class="pt-4">
        <v-icon>mdi-fire</v-icon>
      </v-row>

      <v-row dense justify="center">
        <span class="caption">Sexperts</span>
      </v-row>
    </v-card>

    <v-card
      flat
      class="ma-1"
      height="56"
      width="80"
      @click="() => this.$router.push('/private')"
    >
      <v-row dense justify="center" class="pt-4">
        <v-icon>mdi-message-alert</v-icon>
      </v-row>

      <v-row dense justify="center">
        <span class="caption">Social</span>
      </v-row>
    </v-card>

    <!-- <v-card
      v-if="isSpecialist"
      flat
      class="ma-1"
      height="56"
      width="80"
      @click="() => this.$router.push('/novo-post')"
    >
      <v-row dense justify="center" class="pt-4">
        <v-icon>mdi-plus-circle</v-icon>
      </v-row>

      <v-row dense justify="center">
        <span class="caption">Postar</span>
      </v-row>
    </v-card> -->

    <v-card
      flat
      class="ma-1"
      height="56"
      width="80"
      @click="() => this.$router.push({ name: 'Ajuda' })"
    >
      <v-row dense justify="center" class="pt-4">
        <v-icon>mdi-help</v-icon>
      </v-row>

      <v-row dense justify="center">
        <span class="caption">Ajuda</span>
      </v-row>
    </v-card>

    <v-card flat class="ma-1" height="56" width="80" @click="goToMyProfile">
      <v-row dense justify="center" class="pt-4">
        <v-avatar size="24">
          <v-img :src="currentUser.getRealAvatar()"></v-img>
        </v-avatar>
      </v-row>

      <v-row dense justify="center">
        <span class="caption">Perfil</span>
      </v-row>
    </v-card>
    <v-card
      v-if="!isSpecialist"
      :dark="isAnonymous"
      :color="isAnonymous ? 'primary' : 'white'"
      class="ma-1"
      height="56"
      width="80"
      @click="toggleAnonymous"
    >
      <v-row dense justify="center" class="pt-4">
        <v-icon>{{
          isAnonymous ? "mdi-incognito" : "mdi-incognito-off"
        }}</v-icon>
      </v-row>

      <v-row dense justify="center">
        <span class="caption">Anônimo</span>
      </v-row>
    </v-card>
    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["currentUser", "isAuthenticated", "isAnonymous"]),
  },

  watch: {
    isAnonymous() {
      this.anonymous = this.isAnonymous;
    },
    currentUser() {
      if (this.isAuthenticated) {
        this.isSpecialist = this.currentUser.roles.some(
          (role) => role === "Specialist"
        );
        this.isAdmin = this.currentUser.roles.some((role) => role === "Admin");
      }
    },
  },
  mounted() {
    if (this.isAuthenticated) {
      this.isSpecialist = this.currentUser.roles.some(
        (role) => role === "Specialist"
      );
      this.isAdmin = this.currentUser.roles.some((role) => role === "Admin");
    }
  },
  data: () => ({
    isSpecialist: false,
    isAdmin: false,
    display: false,
    message: "",
    links: [
      { title: "Feed", icon: "mdi-fire" },
      { title: "Postar", icon: "mdi-plus-circle" },
      { title: "Perfil", avatar: "CJ" },
    ],
    comunityGroups: [
      {
        enabledFor: ["Feminino", "Trans mulher"],
        text: "Grupo feminino",
        firebaseLabel: "Feminino",
      },
      {
        enabledFor: ["Masculino", "Trans homem"],
        text: "Grupo masculino",
        firebaseLabel: "Masculino",
      },
      {
        enabledFor: [
          "Homossexual",
          "Bissexual",
          "Trans homem",
          "Trans mulher",
          "Travesti",
          "Transgênero",
          "Pessoa trans",
          "Neutro",
          "Cross gender",
          "Outra",
        ],
        text: "LGBTQIAP+",
        firebaseLabel: "LGBTQIAP+",
      },
      {
        enabledFor: ["Geral"],
        text: "Geral",
        firebaseLabel: "Geral",
      },
    ],
  }),
  methods: {
    isEnabledGroup(user, enabledFor) {
      if (this.isSpecialist || this.isAdmin) {
        return false;
      }
      if (enabledFor[0] == "Geral") {
        return false;
      } else {
        return (
          !enabledFor.includes(user.gender, 0) &&
          !enabledFor.includes(user.sexualOrientation, 0)
        );
      }
    },
    goToMyProfile() {
      this.$router.push({
        name: "Profile",
        params: { userName: this.currentUser.userName },
      });
    },
    goToGroup(group) {
      this.$router
        .push({
          name: "Social",
          params: { group: group },
        })
        .catch(async () => {
          this.$router.push({ name: "Ajuda" });
          this.$nextTick(() => {
            this.$router.push({
              name: "Social",
              params: { group: group },
            });
          });
        });
    },

    toggleAnonymous() {
      if (!this.$route.meta.onlyAnonymous) {
        this.$store
          .dispatch("toggleAnonymous")
          .then(() => {
            const message = `Modo anônimo ${
              this.isAnonymous ? "ligado" : "desligado"
            }`;
            this.displayMessage(message);
          })
          .catch((err) => {
            alert(err);
          });
      } else {
        const message = "Não é póssivel alterar o modo anônimo nesta página";
        this.displayMessage(message);
      }
    },
    displayMessage(message) {
      this.message = message;
      this.display = true;
    },
  },
};
</script>

<style scoped>
.caption {
  user-select: none !important;
}
</style>