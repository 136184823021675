import { UserVModel } from "./Post";

export class Livestream {
    constructor() { }
    private _url: string = "";

    public get url(): string {
        return this._url;
    }
    private _createdDate: Date = new Date();
    public get createdDate(): Date {
        return this._createdDate;
    }
    private _data: Data = new Data();
    public get data(): Data {
        return this._data;
    }
    private _description: string = "";
    public get description(): string {
        return this._description;
    }
    private _status: string = "";
    public get status(): string {
        return this._status;
    }
    private _title: string = "";
    public get title(): string {
        return this._title;
    }
    private _deleted: boolean = false;
    public get deleted(): boolean {
        return this._deleted;
    }
    private _metrics: Metrics = new Metrics();

    public get metrics(): Metrics {
        return this._metrics;
    }
    private _creatorUser: UserVModel = new UserVModel()
    public get creatorUser(): UserVModel {
        return this._creatorUser
    }
    private _thumbnail: string = "";

    public get thumbnail(): string {
        return this._thumbnail;
    }
    static factory = {
        create(live: any) {
            let newLive = new Livestream();

            newLive._url = live.url;
            newLive._title = live.title;
            newLive._createdDate = live.createdDate;
            newLive._data = live.data;
            newLive._description = live.description;
            newLive._deleted = live.deleted;
            newLive._status = live.status;
            newLive._metrics = live.metrics;
            newLive._thumbnail = live.thum_thumbnail;
            newLive._creatorUser = live.creatorUser;




            return newLive;
        },
        createFromDB(
            live: firebase.default.firestore.DocumentData,
            liveId: string,

        ) {
            let newLive = new Livestream();

            newLive._url = live.url;
            newLive._title = live.title;
            newLive._createdDate = live.createdDate;
            newLive._data = live.data;
            newLive._description = live.description;
            newLive._deleted = live.deleted;
            newLive._status = live.status;
            newLive.data.liveId = liveId;
            newLive._metrics = live.metrics;
            newLive._thumbnail = live.thumbnail;
            newLive._creatorUser = live.creatorUser;



            return newLive;
        },
    };


}

class Data {
    connected: boolean = false;
    liveId: string = '';
    playbackIds: Array<PlaybackIds> = [];
    recording: boolean = false;
    streamKey: string = '';
    test: boolean = false;
}

class PlaybackIds {
    policy: string = '';
    id: String = '';
}

class Metrics {
    comments: Number = 0;
    online: Number = 0;
    finalViewers: Number = 0;
    maxViewers: Number = 0;
    //int mediaViewers;
    mediaWatchTime: Number = 0;
    streamDuration: String = '00:00';
    uniqueViewers: Number = 0;


}