<template>
  <div class="sign-up">
    <v-img
      width="300"
      class="mx-auto mb-4"
      src="../../../assets/logotipo_RED.png"
    ></v-img>
    <!-- <h1 class="text-center pb-8">Crie uma nova conta</h1> -->
    <v-text-field
      filled
      color="secondary"
      type="text"
      label="Email"
      v-model="email"
      :hint="errors.emailError"
      :error="errors.emailError != ''"
      persistent-hint
    />
    <v-text-field
      filled
      :value="senha"
      :type="value ? 'text' : 'password'"
      color="secondary"
      label="Senha"
      v-model="senha"
      :hint="errors.passwordError"
      :error="errors.passwordError !== ''"
      :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="() => (value = !value)"
      persistent-hint
    />
    <v-row class="pt-4 pl-3 pb-1">
      <vue-recaptcha
        sitekey="6LefxdEaAAAAAGd6MS8Ux-cosqYPL5_5F5XpC9O-"
        :loadRecaptchaScript="true"
        ref="recaptcha"
        @verify="(r) => verify(r)"
        @expired="expired"
        @error="error"
      >
      </vue-recaptcha>
    </v-row>
    <span class="caption" v-show="showRecaptchaMessage"
      >Complete o reCAPTCHA para continuar</span
    >

    <v-row :loading="loading" justify="center" class="pa-8">
      <v-btn large color="primary" @click="signup">Registrar</v-btn>
    </v-row>
    <v-row justify="center" class="mt-12">
      <v-btn outlined to="/login">
        <v-icon left>mdi-arrow-left</v-icon>
        Voltar ao Login
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";

import { mapGetters } from "vuex";
export default {
  name: "signUp",
  components: { VueRecaptcha },
  data() {
    return {
      loading: false,
      email: "",
      senha: "",
      recaptcha: false,
      showRecaptchaMessage: false,
      value: false,
      errors: {
        emailError: "",
        passwordError: "",
      },
    };
  },
  computed: {
    ...mapGetters(["authError"]),
  },
  watch: {
    authError(val) {
      switch (val) {
        case "auth/invalid-email":
          this.errors.emailError = "Email inválido";
          break;
        case "auth/weak-password":
          this.errors.emailError = "";
          this.errors.passwordError =
            "Você precisa inserir uma senha de no mínimo 6 caracteres";
          break;
        case "auth/user-not-found":
          this.errors.passwordError = "Senha e email não conferem";
          break;
        default:
          this.errors = {
            emailError: "",
            passwordError: "",
          };
          break;
      }
    },
  },
  methods: {
    verify(response) {
      this.recaptcha = true;
    },
    expired() {
      this.recaptcha = false;
    },
    error() {
      this.recaptcha = false;
    },
    signup() {
      this.showRecaptchaMessage = false;

      if (this.recaptcha) {
        this.$store.dispatch("signup", {
          email: this.email,
          password: this.senha,
        });
      } else {
        this.showRecaptchaMessage = true;
      }
    },
  },
};
</script>

