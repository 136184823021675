




















import Vue from "vue";
import PostComponent from "../../components/ComunityPost.vue";
import { ComunityRepository } from "@/repositories";
import { Post } from "@/models/Post";
import { mapGetters } from "vuex";
import {
  UNLOGGED_GO_TO_LOGIN,
  UNLOGGED_POST_READ,
} from "@/core/services/store/analytics.module";
import { Comunity } from "@/models/Comunity";

export default Vue.extend({
  components: { PostComponent },
  computed: {
    ...mapGetters(["currentUser", "isAuthenticated"]),
  },
  data: () => ({
    loadingPosts: true,
    profilePosts: new Array<Post>(),
    postId: "",
    post: new Comunity(),
  }),
  mounted() {
    this.loadPage();
  },
  methods: {
    goToLogin() {
      this.$store.dispatch(UNLOGGED_GO_TO_LOGIN, this.post.id);
      this.$router.push({ name: "Login" });
    },
    loadPage() {
      this.postId = this.$route.params.comunityId;

      if (!this.postId) {
        this.$router.push({ name: "Feed" });
      } else {
        //Verificar se é um id valido
      }

      ComunityRepository.getPost(this.postId)
        .then((post) => {
          if (post) {
            this.post = post;
            this.$store.dispatch(UNLOGGED_POST_READ, this.post.id);
          } else {
            this.$router.push({ name: "Feed" });
          }
        })
        .finally(() => {
          this.loadingPosts = false;
        });
    },
  },
});
