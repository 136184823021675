import Vue from "vue";
import App from "./App.vue";
import vuetify from "./core/plugins/vuetify";
import router from "./router";
import store from "@/core/services/store";
import { auth } from "@/core/plugins/firebase.plugin";
import VueTheMask from "vue-the-mask";
import InfiniteLoading from "vue-infinite-loading";
import firebase from "firebase";
import InstantSearch from 'vue-instantsearch';

Vue.use(InstantSearch);

Vue.use(InfiniteLoading, {
  /* options */
});

Vue.use(VueTheMask);

Vue.config.productionTip = false;

let app;
auth.onAuthStateChanged(async (user) => {
  if (!app) {
    var user = await firebase.auth().currentUser;

    if (user == null) {
      await store.commit("logout");
    } else {
      await store.dispatch("fetchUserFirebaseProfile", user)
    }

    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
