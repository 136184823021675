import { LiveChatRepository } from "@/repositories";
import { User } from "./User";

export class LiveChat {
    constructor() { }
    id: string = ''
    private _createdDate: Date = new Date();
    public get createdDate(): Date {
        return this._createdDate;
    }

    private _message: string = "";
    public get message(): string {
        return this._message;
    }
    private _referenceId: string = "";
    public get referenceId(): string {
        return this._referenceId;
    }
    private _userRef: string = "";
    public get userRef(): string {
        return this._userRef;
    }
    private _deleted: boolean = false;
    public get deleted(): boolean {
        return this._deleted;
    }
    user: User = new User();

    static factory = {
        create(referenceId: string, message: string, user: User) {
            let newLiveChat = new LiveChat();


            newLiveChat._referenceId = referenceId;
            newLiveChat._message = message;
            newLiveChat._deleted = false;
            newLiveChat._userRef = user.id;
            newLiveChat.user = user

            return newLiveChat;
        },
        createFromDB(
            liveMessage: firebase.default.firestore.DocumentData,
            liveMessageId: string,
        ) {
            let newLiveChat = new LiveChat();

            newLiveChat.id = liveMessageId;
            newLiveChat._referenceId = liveMessage.referenceId;
            newLiveChat._createdDate = liveMessage.createdDate;
            newLiveChat._message = liveMessage.message;
            newLiveChat._deleted = liveMessage.deleted;
            newLiveChat._userRef = liveMessage.userRef;
            newLiveChat.user = liveMessage.user

            return newLiveChat;
        },
    };
    publish() {

        return LiveChatRepository.publish(this);

    }
    delete() {
        return LiveChatRepository.delete(this);
    }

}

