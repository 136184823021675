import * as fb from "@/core/plugins/firebase.plugin";
import { baseRepository } from "./Base.repository";
import firebase from "firebase";
import { LiveChat } from "@/models/LiveChat";
import { ProfileVModel } from "@/models/User";

export class liveChatRepository extends baseRepository {
    constructor() {
        super();
    }

    watchLiveStreamChats(liveId?: string, onUpdate?: Function) {
        const livestreamRef = fb.db.collection("lives").doc(liveId);
        return livestreamRef.collection('chats')
            .where("deleted", "==", false)
            .orderBy("createdDate")
            .onSnapshot((querySnapshot) => {
                onUpdate(querySnapshot);

            });


    }
    closeLiveStreamChats(liveId?: string) {
        const livestreamRef = fb.db.collection("lives").doc(liveId);
        const unsubscribe = livestreamRef.collection('chats').onSnapshot(() => { })

        unsubscribe();



    }


    publish(liveChat: LiveChat) {
        const liveRef = fb.db.collection("lives").doc(liveChat.referenceId);
        liveChat = Object.assign(liveChat, { user: new ProfileVModel(liveChat.user) });


        let newLiveChat = this.toObjectRecursive(liveChat);


        return liveRef.collection("chats").add(newLiveChat).then((res) => {
            const batch = fb.db.batch();

            batch.update(
                liveRef, { "metrics.comments": firebase.firestore.FieldValue.increment(1) });

            batch.set(liveRef.collection("viewers").doc(liveChat.user.id),
                { "comments": firebase.firestore.FieldValue.increment(1) }, { merge: true });

            batch.commit();

            return res.id;
        });
    }

    delete(chatMessage: LiveChat) {

        const liveRef = fb.db.collection("lives").doc(chatMessage.referenceId);
        const chatRef = liveRef.collection("chats").doc(chatMessage.id);
        return chatRef.set({ deleted: true }, { merge: true });
    }

}