<template>
  <v-card class="ma-2 mb-6 card-post" elevation="24">
    <v-card-title>
      <v-list-item dense>
        <v-list-item-icon class="mr-2">
          <v-badge
            class="mr-4"
            bordered
            color="primary"
            icon="mdi-star"
            overlap
            bottom
          >
            <v-avatar size="40">
              <v-img src="https://cdn.vuetifyjs.com/images/lists/2.jpg"></v-img>
            </v-avatar>
          </v-badge>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>@usuário</v-list-item-title>
          <v-list-item-subtitle>20h atrás</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn x-small color="primary">seguir</v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-card-title>

    <v-card-title class="justify-center pt-0 pb-0"
      >Título da pergunta</v-card-title
    >

    <v-card-text class="pl-4 pr-4">
      <v-list>
        <v-subheader>{{ responses }} responderam</v-subheader>
        <v-list-item-group color="primary" :value="selected">
          <v-list-item v-for="(item, i) in items" :key="i" disabled>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
              <v-list-item-subtitle
                >{{ item.votes }} votos</v-list-item-subtitle
              >
              <v-progress-linear
                :active="false"
                :value="(item.votes * 100) / responses"
              ></v-progress-linear>
            </v-list-item-content>

            <v-list-item-icon>
              <v-icon v-if="i == selected">mdi-check-circle</v-icon>
              <v-icon v-else>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-row align="center" justify="end" class="pt-4 pb-4 pr-8">
        <v-btn fab elevation="0" small>
          <v-icon>mdi-fire</v-icon>
        </v-btn>
        <span class="caption ml-1 mr-4">23</span>

        <v-btn fab elevation="0" small>
          <v-icon>mdi-chat-outline</v-icon>
        </v-btn>
        <span class="caption ml-1">33</span>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    responses: 12,
    selected: 1,
    items: [
      { text: "Resposta 1", votes: 10 },
      { text: "Resposta 2", votes: 2 },
      { text: "Resposta 3", votes: 0 },
    ],
  }),
};
</script>

<style scoped>
.card-post {
  border-radius: 20px !important;
}
</style>