<template>
  <v-card class="mx-auto mb-4" max-width="400" outlined :disabled="!enabled">
    <v-list-item three-line>
      <v-list-item-avatar rounded size="100" color="grey">
        <v-img
          :src="
            community.thumbnail || require('../../assets/communityPattern.png')
          "
        ></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-row no-gutters>
          <v-row no-gutters>
            <v-avatar size="20" class="mr-2" color="primary">
              <v-img :src="community.creatorUser.avatar"></v-img>
            </v-avatar>
            <div
              class="text-caption"
              v-text="community.creatorUser.displayName"
            ></div>
          </v-row>
          <v-row no-gutters justify="end">
            <v-icon size="18">mdi-account</v-icon>
            <div class="text-caption" v-text="community.members.members"></div>
          </v-row>
        </v-row>
        <v-list-item-title
          class="text-h5"
          v-text="community.title"
        ></v-list-item-title>
        <v-list-item-subtitle
          v-text="community.description"
        ></v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  props: ["community", "enabled"],
};
</script>