import * as fb from "@/core/plugins/firebase.plugin";
import { ProfileVModel, User } from "@/models/User";
import firebaseMain from "firebase";
import "firebase/auth";
import { baseRepository } from "./Base.repository";

export class userRepository extends baseRepository {
  constructor() {
    super();
  }

  create(firebaseUser: firebase.default.User): Promise<void> {
    let newUser = this.toObjectRecursive(User.factory.create(firebaseUser));
    delete newUser["isFollowed"];

    return fb.db
      .collection("users")
      .doc(firebaseUser.uid)
      .set(newUser);
  }

  updateLastLogin() {
    const batch = fb.db.batch();
    const loginCount = firebaseMain.firestore.FieldValue.increment(1);

    batch.update(this.getUserRef(), { ["counters.login"]: loginCount });
    batch.update(this.getUserRef(), { "lastLogin": new Date() });

    batch.commit();
  }

  get(firebaseUser: firebase.default.User): Promise<User | null> {
    return fb.db
      .collection("users")
      .doc(firebaseUser.uid)
      .get()
      .then((snap) => {
        if (snap.exists) {
          return User.factory.createFromDb(snap.data());
        }
        return null;
      });
  }

  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  async changeAvatar(currentUser: User, media: File) {
    const fileName = this.uuidv4();
    const storageRef = fb.storage.ref(
      "/pictures/" + currentUser.id + "/" + fileName
    );
    const uploadTask = storageRef.put(media);

    return uploadTask.on(
      "state_changed",
      (snap) => {
        const progress = (snap.bytesTransferred / snap.totalBytes) * 100;
        switch (snap.state) {
          case firebaseMain.storage.TaskState.PAUSED:
            break;
          case firebaseMain.storage.TaskState.RUNNING:
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL: string) => {
          this.getUserRef()
            ?.set({ avatar: downloadURL }, { merge: true })
            .then(() => { });
          return downloadURL;
        });
      }
    );
  }

  getByUsername(userName: string): Promise<User | null> {
    const user = fb.db
      .collection("users")
      .limit(1)
      .where("userName", "==", userName)
      .get()
      .then((snap) => {
        if (snap.empty) {
          return null;
        } else {
          return User.factory.createFromDb(snap.docs[0].data());
        }
      });

    return user;
  }

  getLoggedUser(): Promise<User | null> {
    const userRef = this.getUserRef();

    const user = fb.db
      .collection("users")
      .doc(userRef?.id)
      .get()
      .then((snap) => {
        if (snap.exists) {
          return User.factory.createFromDb(snap.data());
        }
        return null;
      });

    return user;
  }

  async isFollowed(userIdToBeChecked: string): Promise<boolean> {
    const myUserRef = this.getUserRef();

    if (myUserRef) {
      return await myUserRef
        .collection("following")
        .doc(userIdToBeChecked)
        .get()
        .then((docs) => {
          return docs.exists;
        });
    } else {
      return Promise.reject<false>();
    }
  }

  async follow(userName: string): Promise<void> {
    return fb.db
      .collection("users")
      .limit(1)
      .where("userName", "==", userName)
      .get()
      .then((docs) => {
        if (docs.empty) {
          return Promise.reject("User not found");
        } else {
          const followDoc = docs.docs[0];
          const followRef = followDoc.ref;
          const myUserRef = this.getUserRef();

          if (myUserRef) {
            return myUserRef
              .collection("following")
              .doc(followRef.id)
              .set({
                date: new Date(),
                user: followRef,
                userName: followDoc.data().userName,
              })
              .then(() => {
                this.accountCounters(myUserRef, followRef, true);
              });
          }
        }
      });
  }

  async userIsAvailable(userName: string) {
    return fb.db
      .collection("users")
      .limit(1)
      .where("userName", "==", userName)
      .get()
      .then((docs) => {
        if (docs.empty) {
          return true;
        } else {
          return false;
        }
      });
  }

  async unfollow(userName: string): Promise<void> {
    return fb.db
      .collection("users")
      .limit(1)
      .where("userName", "==", userName)
      .get()
      .then((docs) => {
        if (docs.empty) {
          return Promise.reject("User not Found");
        } else {
          const followDoc = docs.docs[0];
          const followRef = followDoc.ref;
          const myUserRef = this.getUserRef();

          if (myUserRef) {
            return myUserRef
              .collection("following")
              .doc(followRef.id)
              .delete()
              .then(() => {
                this.accountCounters(myUserRef, followRef, false);
                console.log("Document successfully deleted");
              })
              .catch((error) => {
                Promise.reject("Error deleting document " + error);
              });
          } else {
            return Promise.reject("User not Found");
          }
        }
      });
  }

  private accountCounters(
    userRef: firebaseMain.firestore.DocumentReference<
      firebaseMain.firestore.DocumentData
    >,
    followRef: firebaseMain.firestore.DocumentReference<
      firebaseMain.firestore.DocumentData
    >,
    isIncrement: boolean
  ) {
    const batch = fb.db.batch();
    const operationFollowing = firebaseMain.firestore.FieldValue.increment(
      isIncrement ? 1 : -1
    );
    const operationFollowers = firebaseMain.firestore.FieldValue.increment(
      isIncrement ? 1 : -1
    );

    batch.update(userRef, { ["counters.following"]: operationFollowing });
    batch.update(followRef, { ["counters.followers"]: operationFollowers });

    batch.commit();
  }

  getSpecialists(): Promise<Array<ProfileVModel>> {
    return fb.db
      .collection("users")
      .where("roles", "array-contains", "Specialist")
      .orderBy('lastLogin', 'desc')
      .get()
      .then((doc) => {
        const specialists = new Array<ProfileVModel>();

        doc.forEach((snap) => {
          const specialist = new ProfileVModel(
            User.factory.createFromDb(snap.data())
          );

          specialists.push(specialist);
        });

        return specialists;
      });
  }

  toggleAnonymous(anonymous: boolean): Promise<void> {
    return fb.db
      .collection("users")
      .doc(fb.auth.currentUser?.uid)
      .set({ anonymous: anonymous }, { merge: true });
  }

  update(key, value): Promise<void> {
    return fb.db
      .collection("users")
      .doc(fb.auth.currentUser?.uid)
      .set({ [key]: value }, { merge: true });
  }
}
