import * as fb from "@/core/plugins/firebase.plugin";
export abstract class baseRepository {

  protected toObjectRecursive(oldObj: { [key: string]: any }): object {
    let newObj = Object.assign({});
    const keys = Object.keys(oldObj);

    const okObjectConstructorNames = [
      "function Array() { [native code] }",
      "function Object() { [native code] }"
    ]

    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const val = this.getProperty(oldObj, key)
      const typename = typeof val;
      const constructor = val !== undefined && val !== null ? val.constructor.toString() : "";

      if (Array.isArray(val)) {
        this.setProperty(newObj, key, val)
        continue;
      }
      else if (typename === "object" && okObjectConstructorNames.indexOf(constructor) === -1 && !(val instanceof Date)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.setProperty(newObj, key, this.toObjectRecursive(oldObj[key]))
      } else {
        this.setProperty(newObj, key, val)
      }
    }

    return newObj;
  }

  private getProperty<T, K extends keyof T>(o: T, propertyName: string): T[K] {
    return (o[propertyName as K]); // o[propertyName] is of type T[K]
  }

  private setProperty<T, K extends keyof T>(o: T, propertyName: string, val: T[K]) {
    propertyName = propertyName.replace('_', '');

    o[propertyName as K] = val; // o[propertyName] is of type T[K]
  }


  protected getUserRef() {
    if (fb.auth.currentUser) {
      return fb.db.collection("users").doc(fb.auth.currentUser.uid);
    }

    return null;
  }
}

