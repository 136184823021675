


































































































































import { Post } from "../../models/Post";
import { Comunity } from "../../models/Comunity";
import { EroticTales } from "../../models/EroticTales";
import Vue from "vue";
import EroticTalesCommentaries from "./EroticTalesCommentaries.vue";
import Media from "./Media.vue";
import UserDisplay from "./UserDisplay.vue";
import { mapGetters } from "vuex";
import {
  LIKE,
  UNLIKE,
  UNLOGGED_TRY_VOTE,
  VOTE,
} from "../../core/services/store/analytics.module";
import {
  SHAREPOSTMENU,
  SHAREPOSTCOPYLINK,
  UNLOGGED_TRY_LIKE,
} from "@/core/services/store/analytics.module";

export default Vue.extend({
  components: { UserDisplay, Media, EroticTalesCommentaries },
  computed: {
    ...mapGetters(["currentUser", "isAuthenticated"]),
  },
  props: {
    post: [Post, Comunity, EroticTales],
    index: Number,
    hidePostFollow: Boolean,
    previewMode: Boolean,
  },
  data: () => ({
    expand: false,
    hideExpandArrow: false,
    deleteMessage: false,
    copyMessage: false,
    surveyIndex: 0,
  }),
  mounted() {
    this.$nextTick(() => {
      let wrap = this.$refs.wrapper as HTMLElement;
      let content = this.$refs.content as HTMLElement;
      this.hideExpandArrow = wrap.scrollHeight - 6 < content.scrollHeight;
    });
  },
  methods: {
    async sharePost() {
      let refoward = "/e/";

      const shareData = {
        title: "BoutSEX",
        text: this.post.title,
        url:
          window.location.protocol +
          "//" +
          window.location.host +
          refoward +
          this.post.id,
      };

      await navigator.share(shareData);
      this.$store.dispatch(SHAREPOSTMENU, this.post.id);
    },
    deletePost() {
      this.post.delete().then(() => {
        this.deleteMessage = false;
        this.$emit("deletePost", this.index);
      });
    },
    shareCopyLink() {
      let refoward = "/e/";

      const el = document.createElement("textarea");
      el.value =
        window.location.protocol +
        "//" +
        window.location.host +
        refoward +
        this.post.id;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.copyMessage = true;
      this.$store.dispatch(SHAREPOSTCOPYLINK, this.post.id);
    },
    like() {
      if (!this.isAuthenticated) {
        this.$store.dispatch(UNLOGGED_TRY_LIKE, this.post.id);
        return;
      }
      if (!this.post.isLiked) {
        this.post.like().then((post) => {
          this.$store.dispatch(LIKE, {
            userId: this.currentUser.id,
            postId: this.post.id,
          });
        });
      } else {
        this.post.unlike().then(() => {
          this.$store.dispatch(UNLIKE, {
            userId: this.currentUser.id,
            postId: this.post.id,
          });
        });
      }
    },
    vote(index) {
      if (!this.isAuthenticated) {
        this.$store.dispatch(UNLOGGED_TRY_VOTE, this.post.id);
        return;
      }
      this.post.vote(index).then((post) => {
        this.$store.dispatch(VOTE, {
          userId: this.currentUser.id,
          postId: this.post.id,
        });
        this.surveyIndex++;
      });
    },
    increaseComment() {
      this.post.increaseCommentsCounters();
    },
    decreaseComment(value: number) {
      this.post.decreaseCommentsCounters(value);
    },
  },
});
