<template>
  <video
    v-if="url"
    ref="video-player"
    @playing="onPlay"
    @pause="onPause"
    class="video-js vjs-default-skin vjs-big-play-centered"
    controls
  ></video>
</template>

<script>
import "video.js/dist/video-js.css";

import videojs from "video.js";
import "videojs-contrib-hls";
import { LivestreamRepository } from "@/repositories";

export default {
  props: ["url", "liveId", "userId"],
  data: () => ({ singlePlayer: "", isPlaying: false }),
  mounted() {
    this.singlePlayer = videojs(this.$refs["video-player"], {
      bigPlayButton: false,
      textTrackDisplay: false,
      posterImage: true,
      errorDisplay: false,
      controlBar: true,
    });
    this.singlePlayer.src({ src: this.url, type: "application/x-mpegURL" });

    this.singlePlayer.play();
  },
  methods: {
    onPlay() {
      LivestreamRepository.syncUserStatus(this.userId, this.liveId, true);
      // LivestreamRepository.updateUserOnlineInLive(
      //   this.liveId,
      //   this.userId,
      //   true
      // );
      // LivestreamRepository.updateUserEventStream(
      //   this.liveId,
      //   this.userId,
      //   "play"
      // );
    },
    onPause() {
      LivestreamRepository.syncUserStatus(this.userId, this.liveId, false);

      // LivestreamRepository.updateUserOnlineInLive(
      //   this.liveId,
      //   this.userId,
      //   false
      // );
      // LivestreamRepository.updateUserEventStream(
      //   this.liveId,
      //   this.userId,
      //   "pause"
      // );
    },
  },
};
</script>

<style >
@font-face {
  font-family: "VideoJS";
  src: url("https://vjs.zencdn.net/f/1/vjs.eot");
  src: url("https://vjs.zencdn.net/f/1/vjs.eot?#iefix")
      format("embedded-opentype"),
    url("https://vjs.zencdn.net/f/1/vjs.woff") format("woff"),
    url("https://vjs.zencdn.net/f/1/vjs.ttf") format("truetype");
}

.video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before,
.vjs-icon-pause:before {
  content: "\f103";
  font-family: "VideoJS";
}

.video-js .vjs-mute-control .vjs-icon-placeholder:before,
.vjs-icon-volume-high:before {
  content: "\f107";
  font-family: "VideoJS";
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.video-js .vjs-play-control .vjs-icon-placeholder:before,
.vjs-icon-play:before {
  content: "\f101";
  font-family: "VideoJS";
}

.video-js .vjs-picture-in-picture-control .vjs-icon-placeholder:before,
.vjs-icon-picture-in-picture-enter:before {
  content: "\f121";
  font-family: "VideoJS";
}

.video-js .vjs-fullscreen-control .vjs-icon-placeholder:before,
.vjs-icon-fullscreen-enter:before {
  content: "\f108";
  font-family: "VideoJS";
}
.video-js {
  width: 100%;
  height: 100%;
}
</style>