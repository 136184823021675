<template>
  <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small block color="primary" dark v-bind="attrs" v-on="on">
        Editar Perfil
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dense dark color="primary" elevation="0">
        <v-toolbar-title>Editar perfil</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-form
        ref="form"
        v-model="isValid"
        lazy-validation
        @submit.prevent="save"
      >
        <v-card flat width="300" class="registration-card mx-auto mt-8">
          <v-row justify="center" class="mb-2">
            <v-avatar size="60" color="primary">
              <v-img :src="getAvatar"> </v-img>
            </v-avatar>
          </v-row>

          <v-row justify="center" class="pb-8">
            <v-btn
              raised
              small
              outlined
              color="primary"
              :loading="isSelecting"
              @click="onButtonClick"
            >
              Alterar foto
            </v-btn>
          </v-row>

          <input
            ref="uploader"
            class="d-none"
            type="file"
            accept="image/png, image/jpeg, image/bmp"
            @change="onFileChanged"
          />

          <v-text-field
            outlined
            dense
            disabled
            :value="form.userName"
            v-model="form.userName"
            label="Nome de usuário"
            :rules="rules.userName"
          ></v-text-field>
          <v-textarea
            outlined
            rows="3"
            dense
            :value="form.description"
            v-model="form.description"
            label="Bio (opcional)"
          ></v-textarea>
          <v-text-field
            outlined
            dense
            :value="form.site"
            v-model="form.site"
            label="Site (opcional)"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            type="tel"
            :value="form.birthDate"
            v-model="form.birthDate"
            label="Data de nascimento"
            v-mask="['##/##/####']"
            :rules="rules.birthDate"
          ></v-text-field>
          <v-row justify="center" class="mt-4">
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="!isValid"
              @click="save"
            >
              Salvar
            </v-btn>
          </v-row>
        </v-card>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { User } from "@/models/User";
import * as fb from "@/core/plugins/firebase.plugin";

export default {
  data() {
    return {
      user: new User(),
      dialog: false,
      isValid: true,
      loading: false,
      isSelecting: false,
      isSpecialist: false,
      isAnonymous: false,
      newAvatar: null,
      form: {
        avatar: null,
        userName: "",
        description: "",
        site: "",
        birthDate: null,
        phoneNumber: "",
      },
      rules: {
        userName: [(v) => !!v || "Preencha seu nome de usuário"],
        birthDate: [
          (v) => !!v || "Preencha sua data de nascimento",
          (v) =>
            moment(v, "DD/MM/YYYY", true).isValid() == true ||
            "Data de nascimento inválida",
          (v) =>
            moment().diff(
              moment(moment(v, "DD/MM/YYYY")).format("YYYY-MM-DD"),
              "year",
              false
            ) >= 18 || "Não permitimos cadastros de menores de idade",
        ],
        phoneNumber: [
          (v) => !!v || "Insira um número de celular",
          (v) => v.length > 13 || "Número de celular inválido",
        ],
        imageRules: [
          (v) => !!v || "Insira uma Imagem",
          (v) =>
            !v || v.size < 30000000 || "Imagem não pode ultrapassar 30 MB!",
        ],
      },
    };
  },
  mounted() {
    this.form = {
      avatar: this.currentUser.getRealAvatar(),
      userName: this.currentUser.userName,
      description: this.currentUser.description,
      site: this.currentUser.site,
      birthDate: this.getBirthDate,
      phoneNumber: this.currentUser.phoneNumber,
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    today() {
      return moment().format();
    },
    getAvatar() {
      if (this.newAvatar) {
        return URL.createObjectURL(this.newAvatar);
      } else {
        return this.form.avatar;
      }
    },
    getBirthDate() {
      const val = this.currentUser.birthDate;
      const fireBaseTime = new Date(
        val.seconds * 1000 + val.nanoseconds / 1000000
      );
      const momentDate = moment(new Date(fireBaseTime)).format("DD/MM/YYYY");
      return momentDate;
    },
  },
  methods: {
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.newAvatar = e.target.files[0];
    },
    async save() {
      this.loading = true;

      if (this.form.description) {
        await this.user.update("description", this.form.description);
      }

      if (this.form.site) {
        await this.user.update("site", this.form.site);
      }

      if (this.newAvatar) {
        await this.currentUser.changeAvatar(this.newAvatar);
      }

      if (this.form.birthDate) {
        const ano = this.form.birthDate.split("/")[2];
        const mes = this.form.birthDate.split("/")[1];
        const dia = this.form.birthDate.split("/")[0];

        const birthDate = new Date(ano, mes - 1, dia, 12, 0);
        await this.user.update("birthDate", birthDate);
      }

      await this.$store.dispatch(
        "fetchUserFirebaseProfile",
        fb.auth.currentUser
      );

      await setTimeout(() => {
        this.loading = false;
        this.dialog = false;

        this.$emit("profileUpdated");
      }, 1500);
    },
  },
};
</script>