






























import Vue from "vue";
export default Vue.extend({
  data: () => ({
    questionItems: [
      {
        title: "Dúvida sobre a plataforma",
        subtitle: "Tenho uma pergunta sobre a boutSex",
      },
      {
        title: "Fazer uma sugestão",
        subtitle: "Quero fazer uma sugestão",
      },
      {
        title: "Fazer uma pergunta",
        subtitle: "Quero perguntar para um sexpert",
      },
    ],
  }),
  methods: {
    whatsappSend(message) {
      window.open(
        `https://api.whatsapp.com/send?phone=5511989261547&text=%20${message}`,
        "_blank"
      );
    },
  },
});
