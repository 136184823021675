import * as fb from "@/core/plugins/firebase.plugin";
import router from "@/router";
import { User } from "@/models/User";
import { UserRepository } from "@/repositories";

const state = {
  user: new User(),
  isAuthenticated: false,
  isAnonymous: false,
  authError: "",
};

const mutations = {
  setUserProfile(state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.isAnonymous = state.user.anonymous;
  },
  logout(state) {
    state.isAuthenticated = false;
    state.user = {};
    state.isAnonymous = false;
  },
  toggleAnonymous(state) {
    state.user.toggleAnonymous().then(() => {
      state.isAnonymous = state.user.anonymous;
    });
  },
  setAnonymous(state, val) {
    state.isAnonymous = val;
    state.user.toggleAnonymousWithoutUpdateDB(val);
  },
  setAuthError(state, error) {
    state.authError = error;
  },
};

const getters = {
  currentUser(state): User {
    return state.user;
  },
  isAnonymous(state) {
    return state.user.anonymous;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  authError(state) {
    return state.authError;
  },
  openWelcome(state) {
    if (state.user.userName) {
      return false;
    } else {
      return true;
    }
  },
  openCompleteGender(state) {
    if (state.user.gender && state.user.sexualOrientation) {
      return false;
    } else {
      return true;
    }
  },
};

const actions = {
  async signup({ dispatch, commit }, credential) {
    commit("setAuthError", "");
    try {
      // sign user up email and password
      await fb.auth
        .createUserWithEmailAndPassword(credential.email, credential.password)
        .then((data) => {
          if (data.user) {
            // create user profile object in user collection
            UserRepository.create(data.user);

            // fetch user profile and set in state
            dispatch("fetchUserFirebaseProfile", data.user);
          } else {
            Promise.reject("Usuário não encontrado");
          }
        });
    } catch (error: any) {
      commit("setAuthError", error.code);
    }
  },
  async socialLogin({ dispatch }, provider) {
    // login with provider
    await fb.auth.signInWithPopup(provider).then((data) => {
      if (data.additionalUserInfo) {
        if (data.additionalUserInfo.isNewUser) {
          if (data.user) {
            UserRepository.create(data.user);
          }
        }
      }

      dispatch("fetchUserFirebaseProfile", data.user);
    });
  },
  async login({ dispatch, commit }, credential) {
    commit("setAuthError", "");
    // sign user in
    try {
      // fetch user profile and set in state
      const { user } = await fb.auth.signInWithEmailAndPassword(
        credential.email,
        credential.password
      );
      // change route to dashboard
      dispatch("fetchUserFirebaseProfile", user);
    } catch (error: any) {
      commit("setAuthError", error.code);
    }
  },
  fetchUser({ commit }, user) {
    return UserRepository.get(user).then((resp) => {
      commit("setUserProfile", resp);
    });
  },
  fetchUserFirebaseProfile({ commit }, user) {
    return UserRepository.get(user).then((resp) => {
      commit("setUserProfile", resp);

      UserRepository.updateLastLogin();

      if (
        router.currentRoute.name == "Login" ||
        router.currentRoute.name == "SignUp"
      ) {
        router.replace("/feed");
      }
    });
  },
  async fetchUserProfile({ commit }, user) {
    commit("setUserProfile", user);
  },
  toggleAnonymous({ commit }) {
    commit("toggleAnonymous");
  },
  setAnonymous({ commit }, val) {
    commit("setAnonymous", val);
  },
  logout({ commit }) {
    fb.auth.signOut().then(() => commit("logout"));
    router.replace("login");
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
