import * as fb from "@/core/plugins/firebase.plugin";
import { CommentComunity, ReplyComunity } from "@/models/CommentComunity";
import { ProfileVModel } from "@/models/User";
import { baseRepository } from "./Base.repository";
import firebase from "firebase";

export class commentComunityRepository extends baseRepository {
  constructor() {
    super();
  }

  private getCommentMethodFirebase(
    postRef: firebase.firestore.DocumentReference<
      firebase.firestore.DocumentData
    >,
    lastVisible?
  ): firebase.firestore.Query<firebase.firestore.DocumentData> {
    const limit = 5;

    if (lastVisible) {
      return postRef
        .collection("comments")
        .where("deleted", "==", false)
        .orderBy("date", "desc")
        .startAfter(lastVisible)
        .limit(limit);
    } else {
      return postRef

        .collection("comments")
        .where("deleted", "==", false)
        .orderBy("date", "desc")
        .limit(limit);
    }
  }

  getComments(postId: string, lastVisible?): Promise<Array<CommentComunity>> {
    const postRef = fb.db.collection("communities").doc(postId);

    return this.getCommentMethodFirebase(postRef, lastVisible)
      .get()
      .then((doc) => {
        let commentaries = new Array<CommentComunity>();

        doc.forEach(async (snap) => {
          let data = snap.data();
          data.id = snap.id;
          data.ref = snap;
          let comment = CommentComunity.factory.createFromDatabase(data);

          snap.ref
            .collection("replies")
            .where("deleted", "==", false)
            .orderBy("date", "desc")
            .get()
            .then((doc) => {
              comment.replies = [];
              doc.forEach((snapReplys) => {
                let dataReplys = snapReplys.data();
                dataReplys.id = snapReplys.id;
                let reply = ReplyComunity.factory.createFromDatabase(dataReplys);
                comment.replies.push(reply);
              });
            });

          //TODO: Pegar o userRef e coletar os dados de User atribuindo em data.user

          commentaries.push(comment);
        });
        return commentaries;
      });
  }

  post(comment: CommentComunity) {
    delete comment["id"];
    delete comment["ref"];
    delete comment["isValid"];
    const postRef = fb.db.collection("communities").doc(comment.referenceId);
    const userRef = fb.db.collection("users").doc(comment.user.id);

    comment = Object.assign(comment, { user: new ProfileVModel(comment.user) });


    let newComment = this.toObjectRecursive(comment);

    newComment = Object.assign(newComment, { userRef: userRef });

    return postRef.collection("comments").add(newComment).then((comment) => {
      postRef.update("lastUpdate", new Date());
      return comment;
    });
  }

  async reply(reply: ReplyComunity) {
    delete reply["id"];

    const postRef = fb.db.collection("communities").doc(reply.postId);
    const commentRef = postRef.collection("comments").doc(reply.referenceId);
    const userRef = fb.db.collection("users").doc(reply.user.id);

    reply = Object.assign(reply, { user: new ProfileVModel(reply.user) });

    let newReply = this.toObjectRecursive(reply);

    newReply = Object.assign(newReply, { userRef: userRef });

    return commentRef.collection("replies").add(newReply).then((reply) => {
      postRef.update("lastUpdate", new Date())
      return reply;
    });
  }

  delete(comment: CommentComunity) {
    const postRef = fb.db.collection("communities").doc(comment.referenceId);
    return postRef
      .collection("comments")
      .doc(comment.id)
      .set({ deleted: true }, { merge: true });
  }

  deleteReply(reply: ReplyComunity) {
    const postRef = fb.db.collection("communities").doc(reply.postId);
    const commentRef = postRef.collection("comments").doc(reply.referenceId);
    return commentRef
      .collection("replies")
      .doc(reply.id)
      .set({ deleted: true }, { merge: true });
  }
}
