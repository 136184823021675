import * as fb from "../../plugins/firebase.plugin";

import firebase from "firebase";

export const SOCIALLOGIN: string = "fetchUserFirebaseProfile";
export const LOGIN: string = "login";
export const SIGNUP: string = "signup";
export const LOGOUT: string = "logout";
export const TOGGLEANONYMOUS: string = "toggleanonymous";
/**
 * Evento quando o usuário dá Like em um post
 */
export const LIKE: string = "like";
export const VOTE: string = "vote";

/**
 * Evento quando o usuário dá Unlike em um post
 */
export const UNLIKE: string = "unlike";

/**
 * Evento quando o usuário faz um Comentário
 */
export const COMMENT: string = "comment";
/**
 * Evento quando o usuário dá Reply em um comentário
 */
export const REPLY: string = "reply";

/**
 * Evento quando o usuário é menor de idade
 */
export const MINOR: string = "minor";

/**
 * Evento quando o usuário clica para enviar uma mensagem via direct
 */
export const DIRECTMESSAGE: string = "direct_message";

/**
 * Evento quando o usuário segue alguem
 */
export const FOLLOW: string = "follow";

/**
 * Evento quando o usuário deixe de seguir
 */
export const UNFOLLOW: string = "unfollow";

/**
 * Evento quando o usuário abre um site em um perfil
 */
export const OPENPROFILESITE: string = "open_profile_site";

/**
 * Evento quando o usuário abre um site em um perfil
 */
export const SHAREPOSTMENU: string = "share_post_menu";

/**
 * Evento quando o usuário abre um site em um perfil
 */
export const SHAREPOSTCOPYLINK: string = "share_post_copy_link";

/**
 * Evento quando o usuário abre um site em um perfil
 */
export const UNLOGGED_GO_TO_LOGIN: string = "unlogged_go_to_login";

/**
 * Evento quando o usuário abre um site em um perfil
 */
export const UNLOGGED_OPEN_COMMENT: string = "unlogged_open_comment";

/**
 * Evento quando o usuário abre um site em um perfil
 */
export const UNLOGGED_TRY_LIKE: string = "unlogged_try_like";
export const UNLOGGED_TRY_VOTE: string = "unlogged_try_vote";

/**
 * Evento quando o usuário abre um site em um perfil
 */
export const UNLOGGED_POST_READ: string = "unlogged_post_read";

export const COMUNITY_TRY_OPEN_OTHER_GENDER: string =
  "comunity_try_open_other_gender";

/**
 * Evento quando o usuário abre um site em um perfil
 */
export const COMPLETEWIZARD: string = "complete_wizard";
export const COMUNITY_OPEN_GENDER_FEMALE: string =
  "comunity_open_gender_female";

const actions = {
  //LOGIN
  [SOCIALLOGIN]({ dispatch }, provider) {
    fb.analytics.logEvent(firebase.analytics.EventName.LOGIN, provider);
  },
  [LOGIN]({ dispatch }, data: { email; password }) {
    data.password = "";
    fb.analytics.logEvent(firebase.analytics.EventName.LOGIN, data);
  },

  //Cadastro
  [SIGNUP]({ dispatch }, userId) {
    fb.analytics.logEvent(firebase.analytics.EventName.SIGN_UP, {
      userId: userId,
    });
  },

  // Logout
  [LOGOUT]({ dispatch }, userId) {
    fb.analytics.logEvent(LOGOUT, userId);
  },

  // Ações
  [LIKE]({ dispatch }, data: { userId, postId }) {
    fb.analytics.logEvent(LIKE, data);
  },
  [VOTE]({ dispatch }, data: { userId, postId }) {
    fb.analytics.logEvent(VOTE, data);
  },
  [COMPLETEWIZARD]({ dispatch }, userId) {
    fb.analytics.logEvent(COMPLETEWIZARD, userId);
  },
  [UNLIKE]({ dispatch }, data: { userId, postId }) {
    fb.analytics.logEvent(UNLIKE, data);
  },
  [TOGGLEANONYMOUS]({ dispatch }, userId) {
    fb.analytics.logEvent(TOGGLEANONYMOUS, userId);
  },
  [COMMENT]({ dispatch }, userId) {
    fb.analytics.logEvent(COMMENT, userId);
  },
  [REPLY]({ dispatch }, userId) {
    fb.analytics.logEvent(REPLY, userId);
  },
  [FOLLOW]({ dispatch }, userId) {
    fb.analytics.logEvent(FOLLOW, userId);
  },
  [UNFOLLOW]({ dispatch }, userId) {
    fb.analytics.logEvent(UNFOLLOW, userId);
  },
  [COMUNITY_TRY_OPEN_OTHER_GENDER]({ dispatch }, data) {
    fb.analytics.logEvent(COMUNITY_TRY_OPEN_OTHER_GENDER, data);
  },
  [COMUNITY_OPEN_GENDER_FEMALE]({ dispatch }, data) {
    fb.analytics.logEvent(COMUNITY_OPEN_GENDER_FEMALE, data);
  },


  // Menor de idade
  [MINOR]({ dispatch }, userId) {
    fb.analytics.logEvent(MINOR, userId);
  },

  // Clicou para mensagem direta
  [DIRECTMESSAGE]({ dispatch }, userId) {
    fb.analytics.logEvent(DIRECTMESSAGE, userId);
  },

  [OPENPROFILESITE]({ dispatch }, userId) {
    fb.analytics.logEvent(OPENPROFILESITE, userId);
  },
  [SHAREPOSTMENU]({ dispatch }, postId) {
    fb.analytics.logEvent(SHAREPOSTMENU, postId);
  },
  [SHAREPOSTCOPYLINK]({ dispatch }, postId) {
    fb.analytics.logEvent(SHAREPOSTCOPYLINK, postId);
  },

  // Ações deslogadas
  [UNLOGGED_GO_TO_LOGIN]({ dispatch }, postId) {
    fb.analytics.logEvent(UNLOGGED_GO_TO_LOGIN, postId);
  },
  [UNLOGGED_OPEN_COMMENT]({ dispatch }, postId) {
    fb.analytics.logEvent(UNLOGGED_OPEN_COMMENT, postId);
  },
  [UNLOGGED_TRY_LIKE]({ dispatch }, postId) {
    fb.analytics.logEvent(UNLOGGED_TRY_LIKE, postId);
  },
  [UNLOGGED_TRY_VOTE]({ dispatch }, postId) {
    fb.analytics.logEvent(UNLOGGED_TRY_VOTE, postId);
  },
  [UNLOGGED_POST_READ]({ dispatch }, postId) {
    fb.analytics.logEvent(UNLOGGED_POST_READ, postId);
  },
};

export default {
  actions,
};
