

























































































































































import PostPreview from "@/views/components/Post.vue";
import { Post } from "../../../models/Post";
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  components: { PostPreview },
  data: () => ({
    loading: false,
    dialog: false,
    postType: "post",
    surveyOption: "",
    isSpecialist: false,
    valid: false,
    name: "",
    newSurvey: {
      surveyOptions: [],
    },
    newPost: {
      title: "",
      content: "",
      image: null,
      imageUrl: "",
      url: ""
    },
    optionUrlRules: [
      (v) => /^.{0,2000}$/gi.test(v) || "Não pode ultrapassar 2000 caracteres!",
      (v) => {
          if (v){
            if(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(v)){
              return true;
            }else{
              return 'URL inválida'
            }
          } 
          return true;
        },
    ],
    titleRules: [
      (v) => /^.{0,50}$/gi.test(v) || "Não pode ultrapassar 50 caracteres!",
    ],
    optionTitleRules: [
      (v) => !!v || "Título obrigatório",
      (v) => /^.{0,50}$/gi.test(v) || "Não pode ultrapassar 50 caracteres!",
    ],

    contentRules: [
      (v) => !!v || "Insira um conteúdo",
      (v) => (v && v.length <= 2200) || "Não pode ultrapassar 2200 caracteres!",
    ],
    imageRules: [
      // (v) => !!v || "Insira uma Imagem",
      // (v) => !v || v.size < 31457280 || "Imagem não pode ultrapassar 30 MB!",
    ],
  }),

  computed: {
    ...mapGetters(["currentUser"]),
    formErrors(): Boolean {
      if (this.postType === "survey") {
        return !this.valid || this.newSurvey.surveyOptions.length < 1;
      } else {
        return !this.valid || this.newPost.content == "";
      }
    },
    optionRules() {
      const hasMinOptions = (this.newSurvey.surveyOptions.length >
        1) as Boolean;
      const hasMaxOptions = (this.newSurvey.surveyOptions.length <
        7) as Boolean;

      return [
        (v) => /^.{0,50}$/gi.test(v) || "Não pode ultrapassar 50 caracteres!",
        () => hasMinOptions || "Adicione no mínimo duas opções!",
        () => hasMaxOptions || "Adicione no máximo 6 opções!",
      ];
    },
    getPostImage(): string {
      if (this.newPost.image) {
        if (this.newPost.image.length > 1) {
          return "";
        } else {
          return URL.createObjectURL(this.newPost.image[0]);
        }
      } else {
        return "";
      }
    },
    previewPost(): Post {
      return this.createPost();
    },
  },
  watch: {
    postType() {
      this.newSurvey = {
        surveyOptions: [],
      };
      this.newPost = {
        title: "",
        content: "",
        image: null,
        imageUrl: "",
        url: "",
      };
    },
    currentUser() {
      this.checkSexpert();
    },
  },
  mounted() {
    this.checkSexpert();
  },

  methods: {
    pushOption() {
      if (this.surveyOption) {
        this.newSurvey.surveyOptions.push({
          option: {
            votes: 0,
            text: this.surveyOption,
          },
        });
        this.surveyOption = "";
      }
    },
    deleteSurveyOption(i) {
      this.newSurvey.surveyOptions.splice(i, 1);
    },
    checkSexpert() {
      this.isSpecialist = this.currentUser.roles.some(
        (role) => role === "Specialist"
      );
    },

    createPost() {
      const post = Post.factory.create({
        createdDate: new Date(),
        lastUpdate: new Date(),
        id: "000000",
        title: this.newPost.title,
        content: this.newPost.content,
        tags: [],
        counters: {
          likes: 0,
          comments: 0,
          views: 0,
        },
        media: {
          type: "Photo",
          url: this.getPostImage,
          metadata: "",
          mediaItems: [],
        },
        creatorUser: {
          avatar: this.currentUser.avatar,
          displayName: this.currentUser.displayName,
          userName: this.currentUser.userName,
          id: this.currentUser.id,
        },
        url: this.newPost.url
      });

      if (this.postType === "survey") {
        post.media.type = "Survey";
        post.media.mediaItems = this.newSurvey.surveyOptions;
      } else if (this.newPost.image) {
        if (this.newPost.image.length > 1) {
          post.media.type = "Carousel";
          post.media.mediaItems = this.newPost.image.map((e) => {
            switch (e.type.split("/")[0]) {
              case "video":
                return {
                  media: {
                    type: "Video",
                    url: URL.createObjectURL(e),
                  },
                };
              case "image":
                return {
                  media: {
                    type: "Photo",
                    url: URL.createObjectURL(e),
                  },
                };
            }
          });
        } else {
          switch (this.newPost.image[0].type.split("/")[0]) {
            case "video":
              post.media.type = "Video";
              break;
            case "image":
              post.media.type = "Photo";
              break;
          }
        }
      } else {
        post.media.type = "Tweet";
      }

      return post;
    },
    validate() {
      const form = this.$refs.form as HTMLFormElement;
      form.validate();
    },
    reset() {
      const form = this.$refs.form as HTMLFormElement;
      form.reset();
    },
    resetValidation() {
      const form = this.$refs.form as HTMLFormElement;
      form.resetValidation();
    },
    async post() {
      if (this.formErrors) {
        this.validate();
      } else {
        this.loading = true;
        const post = this.createPost();

        await post
          .publish(
            this.currentUser,
            this.postType === "survey" || post.media.type === "Tweet"
              ? post.media
              : this.newPost.image
          )
          .then(() => {})
          .catch((err) => {
            alert(err);
          })
          .finally(() => {
            this.loading = false;
            this.$router.push({ name: "Feed" });
          });
      }
    },
  },
});
