import * as fb from "@/core/plugins/firebase.plugin";
import { baseRepository } from "./Base.repository";
import firebaseMain from "firebase";
import { CommunityGroup } from '../models/CommunityGroup'

export class communityGroupRepository extends baseRepository {
    constructor() {
        super();
    }

    getCommunityGroupsList = async (): Promise<Array<CommunityGroup>> => {
        return fb.db.collection("communitiesList")
            .where("deleted", "==", false)
            .orderBy("counters.members", "desc")
            .get()
            .then((doc) => {
                let communities = new Array<CommunityGroup>();

                doc.forEach((snap) => {
                    let post = CommunityGroup.factory.createFromDB(snap.data(), snap.id);
                    communities.push(post);
                });
                return communities;
            });
    }

}
