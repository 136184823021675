











































































































import Vue from "vue";
import algoliasearch from "algoliasearch/lite";

export default Vue.extend({
  data: () => ({
    collapsed: true,
    searchQuery: "",
    searchClient: algoliasearch(
      process.env.VUE_APP_ALGOLIA_APP_ID,
      process.env.VUE_APP_ALGOLIA_API_KEY
    ),
  }),
  watch: {
    collapsed(val) {
      if (!val) {
        this.$nextTick(() => {
          const searchInput = this.$refs.searchInput as HTMLFieldSetElement;
          searchInput.focus();
        });
      }
    },
  },
  methods: {
    changeCollapsed() {
      this.collapsed = !this.collapsed;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
});
