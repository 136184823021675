<template>
  <span>
    <v-chip
      class="label-chip mt-4"
      label
      small
      v-text="`${currentSlide} de ${slides}`"
    ></v-chip>
    <VueSlickCarousel
      :arrows="false"
      :dots="false"
      class="carousel-item"
      ref="carousel"
      :accessibility="true"
      @beforeChange="(osn, nsn) => updateIndex(osn, nsn)"
    >
      <slot />
    </VueSlickCarousel>
  </span>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  props: { slides: Number },
  components: { VueSlickCarousel },
  data: () => ({
    currentSlide: 1,
  }),
  methods: {
    updateIndex(o, n) {
      this.currentSlide = n + 1;
    },
  },
};
</script>

<style scoped>
.label-chip {
  position: absolute;
  z-index: 3;
  left: 80%;
  opacity: 0.7;
}
</style>