import { UserRepository } from "@/repositories";
import "firebase/auth";

export class User {
  constructor() {}

  private _id: string = "";
  public get id(): string {
    return this._id;
  }

  private _name: string = "";
  public get name(): string {
    return this._name;
  }

  private _email: string = "";
  public get email(): string {
    return this._email;
  }

  private _phoneNumber: string = "";
  public get phoneNumber(): string {
    return this._phoneNumber;
  }

  private _gender: string = "";
  public get gender(): string {
    return this._gender;
  }
  public set gender(value: string) {
    this._gender = value;
  }
  private _sexualOrientation: string = "";
  public get sexualOrientation(): string {
    return this._sexualOrientation;
  }
  public set sexualOrientation(value: string) {
    this._sexualOrientation = value;
  }

  private _birthDate: Date = new Date();
  public get birthDate(): Date {
    return this._birthDate;
  }

  private _showPhoneNumber: boolean = true;
  public get showPhoneNumber(): boolean {
    return this._showPhoneNumber;
  }

  public counters: UserCounters = new UserCounters();

  private _createdDate: Date = new Date();
  public get createdDate(): Date {
    return this._createdDate;
  }

  private _isFollowed: boolean = false;
  public get isFollowed(): boolean {
    return this._isFollowed;
  }
  public set isFollowed(value: boolean) {
    this._isFollowed = value;
  }

  public changeAvatar(media: File) {
    return UserRepository.changeAvatar(this, media).then((avatarUrl) => {
      // this._avatar = avatarUrl
    });
  }

  private _anonymous: boolean = false;

  public get anonymous(): boolean {
    return this._anonymous;
  }

  public requestChangeRoleToSpecialist(phoneNumber: string): Promise<void> {
    return UserRepository.update("phoneNumber", phoneNumber).then(() => {
      UserRepository.update("roles", ["User", "WannaBeSpecialist"]);
    });
  }

  public getRealAvatar(): string {
    return this._avatar;
  }

  public toggleAnonymousWithoutUpdateDB(val?: boolean) {
    const rolesArray = Object.values(this.roles) as Array<string>;

    if (rolesArray.some((role) => role !== "Specialist")) {
      if (val == null) {
        this._anonymous = !this._anonymous;
      } else {
        this._anonymous = val;
      }
    }
  }

  public toggleAnonymous(val?: boolean): Promise<void> {
    const rolesArray = Object.values(this.roles) as Array<string>;

    if (rolesArray.some((role) => role !== "Specialist")) {
      if (val == null) {
        this._anonymous = !this._anonymous;
      } else {
        this._anonymous = val;
      }

      return UserRepository.toggleAnonymous(this.anonymous);
    } else {
      return Promise.reject("Usuário não é um especialista");
    }
  }

  private _roles: ["User" | "Specialist" | "Admin"] = ["User"];
  public get roles(): ["User" | "Specialist" | "Admin"] {
    return this._roles;
  }

  private _userName: string = "";
  public get userName(): string {
    return this._userName;
  }

  public changeUsername() {
    //Verifica se o username é valido
    //Verifica se já está em uso
    //Salva no banco de dados
  }

  private _avatar: string = "";
  public get avatar(): string {
    if (this.anonymous) {
      return require("@/assets/incognito-icon.png"); //TODO: Por um avatar anonimo default
    } else {
      return this._avatar;
    }
  }

  public get displayName(): string {
    if (this.anonymous) {
      return "Anônimo"; //TODO: Por um avatar anonimo default
    } else {
      return this._userName;
    }
  }

  private _site: string = "";
  public get site(): string {
    return this._site;
  }

  private _description: string = "";
  public get description(): string {
    return this._description;
  }

  static factory = {
    /// Create a new user instance from firebase.User
    create(firebaseUser: firebase.default.User) {
      let newUser = new User();
      newUser.importFromFirebase(firebaseUser);
      return newUser;
    },

    /// Create a user from firestore database
    createFromDb(databaseUser: any): User {
      let newUser = new User();

      newUser._anonymous = databaseUser.anonymous;
      newUser._avatar = databaseUser.avatar;
      newUser._birthDate = databaseUser.birthDate;
      newUser.counters = databaseUser.counters;
      newUser._createdDate = databaseUser.createdDate;
      newUser._description = databaseUser.description;
      newUser._email = databaseUser.email;
      newUser._id = databaseUser.id;
      newUser._name = databaseUser.name;
      newUser._phoneNumber = databaseUser.phoneNumber;
      newUser._roles = databaseUser.roles;
      newUser._site = databaseUser.site;
      newUser._userName = databaseUser.userName;
      newUser._showPhoneNumber = databaseUser.showPhoneNumber;
      newUser._sexualOrientation = databaseUser.sexualOrientation;
      newUser._gender = databaseUser.gender;

      return newUser;
    },
  };

  importFromFirebase(firebaseUser: firebase.default.User) {
    this._name = firebaseUser.displayName ?? "";
    this._email = firebaseUser.email ?? "";
    this._id = firebaseUser.uid;
    this._phoneNumber = firebaseUser.phoneNumber ?? "";
    this._avatar =
      firebaseUser.photoURL ??
      "https://firebasestorage.googleapis.com/v0/b/boutsex-social.appspot.com/o/avatar-default.png?alt=media&token=f3498c3e-5813-4655-ad8a-ce64989862e1";
  }

  public follow() {
    UserRepository.follow(this._userName).then(() => {
      this._isFollowed = true;
      this.increaseFollowers();
    });
  }

  public unfollow() {
    UserRepository.unfollow(this._userName).then(() => {
      this._isFollowed = false;
      this.decreaseFollowers();
    });
  }

  increaseFollowers() {
    this.counters.followers = this.counters.followers + 1;
  }

  decreaseFollowers() {
    if (this.counters.followers > 0) {
      this.counters.followers = this.counters.followers - 1;
    }
  }

  public sendMessage(userName: string, message: string) {
    //TODO: Mensagem Whatsapp
  }

  public delete() {
    //TODO: Buscar todos os usuarios que eu sigo e recontar os followers
  }

  public update(key, value) {
    return UserRepository.update(key, value);
  }

  public async checkFollowed() {
    return await UserRepository.isFollowed(this.id);
  }
}

export class UserCounters {
  constructor(followers: number = 0, following: number = 0) {
    this.followers = followers;
    this.following = following;
  }

  followers: number = 0;
  following: number = 0;
}

// Representa um resumo do User
export class ProfileVModel {
  constructor(user: User) {
    this.id = user.id;
    this.displayName = user.displayName;
    this.avatar = user.avatar;
  }

  public id: string = "";
  public displayName: string = "";
  public avatar: string = "";
}

//
//// Lista de perfis que sigo
//class Following{
//    public profiles: Array<Profile> = []
//}
//
//// Lista dos meus seguidores
//class Follower{
//    public profiles: Array<Profile> = []
//}
