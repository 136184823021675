import * as fb from "@/core/plugins/firebase.plugin";
import { EroticTales } from "@/models/EroticTales";
import { User } from "@/models/User";
import { baseRepository } from "./Base.repository";
import firebaseMain from "firebase";

export class eroticTalesRepository extends baseRepository {
  constructor() {
    super();
  }

  private getPostMethodFirebase(
    lastVisible?
  ): firebaseMain.firestore.Query<firebaseMain.firestore.DocumentData> {
    const limit = 5;

    if (lastVisible) {
      return fb.db
        .collection("eroticTales")
        .orderBy("createdDate", "desc")
        .startAfter(lastVisible)
        .limit(limit);
    } else {
      return fb.db
        .collection("eroticTales")
        .orderBy("createdDate", "desc")
        .limit(limit);
    }
  }

  getPost(postId: string): Promise<EroticTales> {
    return fb.db
      .collection("eroticTales")
      .doc(postId)
      .get()
      .then(async (snap) => {
        let post = EroticTales.factory.createFromDB(snap.data(), snap.id, snap);
        const user = this.getUserRef();

        if (user) {
          await snap.ref
            .collection("likes")
            .doc(user.id)
            .get()
            .then((doc) => {
              if (doc.exists) {
                post.isLiked = true;
              }
            });
          await snap.ref
            .collection("votes")
            .doc(user.id)
            .get()
            .then((doc) => {
              if (doc.exists) {
                post.isVoted = doc.data().index;
              }
            });
        }
        return post;
      });
  }

  getPosts(lastVisible?): Promise<Array<EroticTales>> {
    return this.getPostMethodFirebase(lastVisible)
      .where("deleted", "==", false)
      .get()
      .then((doc) => {
        let posts = new Array<EroticTales>();
        const user = this.getUserRef();

        doc.forEach((snap) => {
          let post = EroticTales.factory.createFromDB(snap.data(), snap.id, snap);
          if (user) {
            snap.ref
              .collection("likes")
              .doc(user.id)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  post.isLiked = true;
                }
              });
            snap.ref
              .collection("votes")
              .doc(user.id)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  post.isVoted = doc.data().index;
                }
              });
          }
          posts.push(post);
        });

        return posts;
      });
  }

  getPostsFromUserName(userName: string, lastVisible?): Promise<Array<EroticTales>> {
    return this.getPostMethodFirebase(lastVisible)
      .where("creatorUser.userName", "==", userName)
      .where("deleted", "==", false)
      .get()
      .then((doc) => {
        let posts = new Array<EroticTales>();
        const user = this.getUserRef();

        doc.forEach((snap) => {
          let post = EroticTales.factory.createFromDB(snap.data(), snap.id, snap);
          if (user) {
            snap.ref
              .collection("likes")
              .doc(user.id)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  post.isLiked = true;
                }
              });
            snap.ref
              .collection("votes")
              .doc(user.id)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  post.isVoted = doc.data().index;
                }
              });
          }
          posts.push(post);
        });

        return posts;
      });
  }

  getComments(postId: string) {
    return fb.db
      .collection("eroticTales")
      .doc(postId)
      .collection("comments")
      .get()
      .then((doc) => { });
  }

  like(post: EroticTales) {
    const postRef = fb.db.collection("eroticTales").doc(post.id);
    let user = this.getUserRef();

    if (user) {
      const like = Object.assign({}, { userRef: user, date: new Date() });

      return postRef
        .collection("likes")
        .doc(user.id)
        .set(like)
        .then(() => {
          this.updateLikesCounter(postRef, post);
        });
    } else {
      return Promise.reject("Usuário não encontrado");
    }
  }

  vote(post: EroticTales, index: number) {
    const postRef = fb.db.collection("eroticTales").doc(post.id);
    let user = this.getUserRef();

    if (user) {
      const vote = Object.assign({}, { userRef: user, date: new Date(), index: index });

      return postRef
        .collection("votes")
        .doc(user.id)
        .set(vote)
        .then(() => {
          this.updateVotesCounter(postRef, post);
        });
    } else {
      return Promise.reject("Usuário não encontrado");
    }
  }

  updateVotesCounter(
    postRef: firebaseMain.firestore.DocumentReference<
      firebaseMain.firestore.DocumentData
    >,
    post: EroticTales,

  ) {
    return postRef.update(
      { "media.mediaItems": post.media.mediaItems }
    );
  }

  updateLikesCounter(
    postRef: firebaseMain.firestore.DocumentReference<
      firebaseMain.firestore.DocumentData
    >,
    post: EroticTales
  ) {
    return postRef.update({ ["counters.likes"]: post.counters.likes });
  }

  updateCommentsCounter(post: EroticTales, value) {
    const postRef = fb.db.collection("eroticTales").doc(post.id);
    const number = firebaseMain.firestore.FieldValue.increment(value);
    return postRef.update({ ["counters.comments"]: number });
  }

  unlike(post: EroticTales) {
    const postRef = fb.db.collection("eroticTales").doc(post.id);
    let user = this.getUserRef();

    if (user) {
      return postRef
        .collection("likes")
        .doc(user.id)
        .delete()
        .then(() => {
          this.updateLikesCounter(postRef, post);
        });
    } else {
      return Promise.reject("Usuário não encontrado");
    }
  }

  publishSurvey(post: any) {

    let newPost = this.toObjectRecursive(post);
    delete newPost["id"];
    delete newPost["isLiked"];
    delete newPost["isVoted"];
    delete newPost["postRef"];

    return fb.db.collection("eroticTales").add(newPost);
  }

  publishTweet(post: any) {
    let newPost = this.toObjectRecursive(post);
    delete newPost["id"];
    delete newPost["isLiked"];
    delete newPost["isVoted"];
    delete newPost["postRef"];

    return fb.db.collection("eroticTales").add(newPost);
  }

  async publish(currentUser: User, post: any, media: Array<File>) {
    let newPost = this.toObjectRecursive(post);
    delete newPost["id"];
    delete newPost["isLiked"];
    delete newPost["isVoted"];
    delete newPost["postRef"];


    if (media.length > 1) {
      let promises = [];

      //aqui o upload de multiple files
      for (const [i, m] of media.entries()) {
        const fileName = this.uuidv4();
        const storageRef = fb.storage.ref(
          "/posts/" + currentUser.id + "/" + fileName
        );
        const uploadTask = storageRef.put(m);
        promises.push(
          new Promise<string>((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              (snap) => {
                const progress =
                  (snap.bytesTransferred / snap.totalBytes) * 100;
                switch (snap.state) {
                  case firebaseMain.storage.TaskState.PAUSED:
                    break;
                  case firebaseMain.storage.TaskState.RUNNING:
                    break;
                }
              },
              (error) => {
                reject(error);
                console.log(error);
              },
              () => {
                uploadTask.snapshot.ref
                  .getDownloadURL()
                  .then((downloadURL) => {
                    newPost = Object.assign({}, newPost, {
                      userRef: this.getUserRef(),
                    });
                    const media = post.media;
                    const mediaItemFragment = JSON.parse(
                      JSON.stringify(media.mediaItems[i])
                    );
                    mediaItemFragment.media.url = downloadURL;

                    media.mediaItems[i] = mediaItemFragment;
                    media.url = "";
                    media.fileName = fileName;

                    newPost = Object.assign({}, newPost, { media: media });

                    resolve(downloadURL);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            );
          })
        );
      };
      await Promise.all(promises).then(() => {

        return fb.db.collection("eroticTales").add(newPost);
      });
    } else {
      const fileName = this.uuidv4();
      const storageRef = fb.storage.ref(
        "/posts/" + currentUser.id + "/" + fileName
      );
      const uploadTask = storageRef.put(media[0]);

      return uploadTask.on(
        "state_changed",
        (snap) => {
          const progress = (snap.bytesTransferred / snap.totalBytes) * 100;
          switch (snap.state) {
            case firebaseMain.storage.TaskState.PAUSED:
              break;
            case firebaseMain.storage.TaskState.RUNNING:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        async () => {
          await uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            newPost = Object.assign({}, newPost, {
              userRef: this.getUserRef(),
            });
            const media = post.media;
            media.url = downloadURL;
            media.fileName = fileName;
            newPost = Object.assign({}, newPost, { media: media });

            return fb.db.collection("eroticTales").add(newPost);
          });
        }
      );
    }
  }

  delete(post: EroticTales) {
    return fb.db
      .collection("eroticTales")
      .doc(post.id)
      .set({ deleted: true }, { merge: true });
  }

  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
