<template>
  <v-slide-group v-model="model" class="pa-4">
    <v-slide-item v-for="specialist in specialists" :key="specialist.id">
      <v-col
        class="ma-1"
        height="100"
        width="100"
        @click="goToProfile(specialist.displayName)"
      >
        <v-card-text>
          <v-row align="center" justify="center">
            <v-badge
              class="mb-2"
              bordered
              color="primary"
              icon="mdi-star"
              overlap
              bottom
            >
              <v-avatar size="40" color="primary">
                <v-img :src="specialist.avatar"></v-img>
              </v-avatar>
            </v-badge>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row align="center" justify="center">
            <p
              class="caption font-weight-bold"
              v-text="specialist.displayName"
            ></p>
          </v-row>
        </v-card-actions>
      </v-col>
    </v-slide-item>
  </v-slide-group>
</template>

<script>
export default {
  props: ["specialists"],
  data: () => ({ model: null }),
  methods: {
    goToProfile(userName) {
      if (userName) {
        this.$router.push({ name: "Profile", params: { userName: userName } });
      }
    },
  },
};
</script>