<template>
  <v-dialog fullscreen v-model="open">
    <v-card flat>
      <v-system-bar fixed color="transparent" v-if="stepActive !== 1">
        <v-spacer></v-spacer>
        <v-icon :color="stepActive > 1 ? 'primary' : 'grey'">mdi-minus</v-icon>
        <v-icon :color="stepActive > 2 ? 'primary' : 'grey'">mdi-minus</v-icon>
        <v-icon :color="stepActive > 3 ? 'primary' : 'grey'">mdi-minus</v-icon>
        <v-spacer></v-spacer>
      </v-system-bar>
      <v-stepper v-model="stepActive" alt-labels class="stepper">
        <v-stepper-items>
          <v-stepper-content step="1">
            <complete-gender-step
              :currentUser="currentUser"
              ref="registration"
              @setAnonymous="setAnonymous"
              @setSpecialist="setSpecialist"
              @setShowPhoneNumber="setShowPhoneNumber"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
    <v-footer fixed color="#fff">
      <v-row dense class="pa-0 pb-4 pt-4" justify="space-around">
        <v-btn
          @click="previousStep"
          :loading="loading"
          v-show="stepActive !== 1"
          ><v-icon left>mdi-arrow-left</v-icon>Voltar</v-btn
        >
        <v-btn
          @click="nextStep"
          :loading="loading"
          :color="isAnonymous ? 'secondary' : 'primary'"
        >
          <v-icon left v-if="isAnonymous">mdi-incognito</v-icon>
          <span
            v-text="stepActive === steps.length ? 'Finalizar' : 'Continuar'"
          ></span>
          <v-icon right>mdi-arrow-right</v-icon></v-btn
        >
      </v-row>
    </v-footer>
  </v-dialog>
</template>

<script>
import { User } from "@/models/User";
import { mapGetters } from "vuex";
import CompleteGenderStep from "./CompleteGender.Step.vue";
import * as fb from "@/core/plugins/firebase.plugin";
import GenericStep from "./Generic.Step.vue";
import boutSexLogo from "../../../assets/boutsex-logo-lg.svg";
import seximage from "../../../assets/sex.png";
import incognitoImage from "../../../assets/incognito.png";
import specialistImage from "../../../assets/specialist.png";
import { COMPLETEWIZARD } from "../../../core/services/store/analytics.module";

export default {
  components: {
    CompleteGenderStep,
    GenericStep,
  },
  computed: {
    ...mapGetters(["currentUser", "isAuthenticated", "openWelcome", "openCompleteGender"]),

    open() {
      return this.isAuthenticated && !this.openWelcome && this.openCompleteGender;
    },
  },

  data: () => ({
    stepActive: 1,
    loading: false,
    isAnonymous: false,
    isSpecialist: false,
    user: new User(),
    showPhoneNumber: false,
    steps: [
      {
        title: "Cadastro de especialista",
        text: "Você se cadastrou como especialista. Iremos avaliar seus dados e entrar em contato para ativar seu perfil de Sexólogo dentro da boutSex",
        imgUrl: specialistImage,
      },
    ],
  }),
  methods: {
    async nextStep()  {
      if (await this.validateFormAndNext()) {
        this.saveUser(this.$refs.registration.form);
      } 
    },
    async validateFormAndNext() {
      await this.$refs.registration.validate();
      if (this.$refs.registration.isValid) {
        return true;
      }else{
          return false;
      }
    },
    previousStep() {
      this.stepActive = this.stepActive - 1;
    },
    setSpecialist(isSpecialist) {
      this.isAnonymous = true;
      this.isSpecialist = isSpecialist;
    },
    setShowPhoneNumber(showPhoneNumber) {
      this.showPhoneNumber = showPhoneNumber;
    },
    setAnonymous(val) {
      this.loading = true;
      this.isAnonymous = val;

      this.user
        .toggleAnonymous(val)
        .then(() => {
          this.isAnonymous = val;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async saveUser(result) {
      this.loading = true;
      //if (this.isSpecialist) {
      //  await this.user
      //    .requestChangeRoleToSpecialist(result.phoneNumber)
      //    .then(() => {
      //      this.loading = false;
      //    });
      //}
      if (result.userName) {
        this.user.update("userName", result.userName.toLowerCase());
      }

      if (result.description) {
        this.user.update("description", result.description);
      }

      if (result.site) {
        this.user.update("site", result.site);
      }

      if (result.avatar) {
        this.currentUser.changeAvatar(result.avatar);
      }

      if (result.gender) {
        this.user.update("gender", result.gender);
      }

      if (result.sexualOrientation) {
        this.user.update("sexualOrientation", result.sexualOrientation);
      }

      if (result.birthDate) {
        const ano = result.birthDate.split("/")[2];
        const mes = result.birthDate.split("/")[1];
        const dia = result.birthDate.split("/")[0];

        const birthDate = new Date(ano, mes - 1, dia, 12, 0);
        this.user.update("birthDate", birthDate);
      }

      //this.user.update("showPhoneNumber", this.showPhoneNumber);
      //this.$store.dispatch(COMPLETEWIZARD, this.currentUser.id);
      this.$store.dispatch("fetchUser", fb.auth.currentUser);
    },
    saveStepThree(result) {
      if (result.isSpecialist) {
        this.user.requestChangeRoleToSpecialist(result.phoneNumber).then(() => {
          this.nextStep();
        });
      } else {
        this.nextStep();
      }
    },
  },
};
</script>

<style lang="scss">
.caption {
  user-select: none !important;
}

.stepper {
  box-shadow: none !important;
  .v-stepper__wrapper {
    height: auto !important;
    // max-height: 85vh !important;
    // overflow: auto !important;

    .v-stepper__content {
      padding: 0 !important;
      // max-height: 85vh !important;
      // overflow: auto !important;
    }
  }
}
</style>