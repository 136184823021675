import { render, staticRenderFns } from "./CompleteGender.Step.vue?vue&type=template&id=1383f4ca&scoped=true&"
import script from "./CompleteGender.Step.vue?vue&type=script&lang=js&"
export * from "./CompleteGender.Step.vue?vue&type=script&lang=js&"
import style0 from "./CompleteGender.Step.vue?vue&type=style&index=0&id=1383f4ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1383f4ca",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VCard,VCardText,VCombobox,VForm})
