<template>
  <v-main class="content pt-0">
    <v-layout justify-center align-center fill-height>
      <v-col cols="2" align="center" justify="center">
        <v-img
          alt="Logotipo"
          class="shrink mr-4 mb-3"
          contain
          src="@/assets/logotipo_RED.png"
          transition="scale-transition"
          width="120"
        />
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-col>
    </v-layout>
  </v-main>
</template>

<script>
export default {};
</script>

<style>
.loader {
  width: 100px !important;
}
</style>