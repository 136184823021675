<template>
  <v-form ref="form" v-model="isValid" lazy-validation @submit.prevent="save">
    <v-card flat width="300" class="registration-card">
      <v-card-text class="mb-4">
        <h2 class="text-center">Complete seu cadastro</h2>
      </v-card-text>
      <v-row justify="center" class="mb-2">
        <v-avatar size="60" color="primary">
          <v-img :src="form.avatarUrl"> </v-img>
        </v-avatar>
      </v-row>

      <v-row justify="center">
        <v-btn
          raised
          small
          outlined
          color="primary"
          :loading="isSelecting"
          @click="onButtonClick"
        >
          Alterar foto
        </v-btn>
      </v-row>
      <v-row justify="center" class="pb-8">
        <div class="v-text-field__details">
          <div class="v-messages theme--light error--text" role="alert">
            <div class="v-messages__wrapper">
              <div class="v-messages__message d-flex justify-center pt-1">
                {{ messageAvatarError }}
              </div>
            </div>
          </div>
        </div>
        <span class="center"></span>
      </v-row>

      <v-file-input
        ref="uploader"
        type="file"
        class="d-none"
        accept="image/png, image/jpeg, image/bmp"
        v-model="form.avatar"
        :rules="rules.avatar"
      />

      <v-text-field
        outlined
        dense
        ref="userNameField"
        :value="form.userName"
        v-model="form.userName"
        label="Nome de usuário"
        :rules="userNameRule"
      ></v-text-field>
      <v-text-field
        outlined
        dense
        :value="form.description"
        v-model="form.description"
        label="Bio (opcional)"
      ></v-text-field>

      <v-text-field
        outlined
        dense
        type="tel"
        :value="form.birthDate"
        v-model="form.birthDate"
        label="Data de nascimento"
        v-mask="['##/##/####']"
        :rules="rules.birthDate"
      ></v-text-field>
      <v-combobox
        outlined
        dense
        :items="genders"
        label="Gênero"
        v-model="form.gender"
        :rules="rules.gender"
      ></v-combobox>
      <v-combobox
        outlined
        dense
        :items="sexualOrientations"
        v-model="form.sexualOrientation"
        :rules="rules.sexualOrientation"
        label="Orientação sexual"
      ></v-combobox>
      <v-card-text class="pa-0">
        <p>
          Entrar na plataforma como Profissional? (Sexólogo, Terapeuta,
          Psicólogo...)
        </p>

        <v-btn-toggle v-model="isSpecialist" rounded mandatory color="primary">
          <v-btn :value="true"> Sim </v-btn>
          <v-btn :value="false"> Não </v-btn>
        </v-btn-toggle>
      </v-card-text>

      <v-card-text v-if="isSpecialist" class="pa-0 pt-4">
        <p>
          Para continuar como profissional, precisamos do seu número de WhatsApp
        </p>

        <v-text-field
          outlined
          dense
          v-model="form.phoneNumber"
          label="WhatsApp"
          v-mask="['(##) ####-####', '(##) #####-####']"
          :rules="rules.phoneNumber"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          :value="form.site"
          v-model="form.site"
          label="Site (opcional)"
        ></v-text-field>
        <v-checkbox
          v-if="isSpecialist"
          v-model="showPhoneNumber"
          label="Permitir que usuários entrem em contato neste WhatsApp pra falar de agendamento de consultas, dúvidas e venda de produtos?"
        ></v-checkbox>
      </v-card-text>
      <v-card-text class="pa-0 pt-4" v-if="!isSpecialist">
        <p>Ativar o modo anônimo?</p>

        <v-btn-toggle v-model="isAnonymous" rounded mandatory color="primary">
          <v-btn :value="true"> Sim </v-btn>
          <v-btn :value="false"> Não </v-btn>
        </v-btn-toggle>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { UserRepository } from "@/repositories";
import moment from "moment";
import Vue from "vue";
import { MINOR } from "@/core/services/store/analytics.module";

export default Vue.extend({
  props: ["backStepActive", "saveButtonText", "backButtonText", "currentUser"],
  computed: {
    formattedDate() {
      return this.form.birthDate
        ? moment(this.form.birthDate).format("DD/MM/YYYY")
        : "";
    },
    today() {
      return moment().format();
    },
    userNameRule() {
      return [
        (v) => !!v || "Preencha seu nome de usuário",
        (v) =>
          !!/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/gim.test(v) ||
          "Seu nome de usuário não pode conter espaços, precisa começar e terminar com uma letra ou número",
        (v) => !!this.isUserAvailable || "Esse nome de usuário já está em uso",
      ];
    },
  },
  data() {
    return {
      minorAlert: true,
      isValid: true,
      isSelecting: false,
      modal: false,
      isSpecialist: false,
      showPhoneNumber: false,
      isAnonymous: false,
      messageAvatarError: "",
      isUserAvailable: true,
      awaitingSearch: false,
      genders: [
        "Feminino",
        "Masculino",
        "Trans homem",
        "Trans mulher",
        "Travesti",
        "Transgênero",
        "Pessoa trans",
        "Neutro",
        "Sem gênero",
        "Cross gender",
        "Outra",
      ],
      sexualOrientations: [
        "Heterossexual",
        "Homossexual",
        "Bissexual",
        "Outra",
      ],
      form: {
        avatar: null,
        avatarUrl: "",
        userName: "",
        description: "",
        site: "",
        birthDate: null,
        phoneNumber: "",
        gender: "",
        sexualOrientation: "",
      },
      rules: {
        gender: [(v) => !!v || "Preencha o seu gênero"],
        sexualOrientation: [(v) => !!v || "Preencha sua orientação sexual"],
        birthDate: [
          (v) => !!v || "Preencha sua data de nascimento",
          (v) =>
            moment(v, "DD/MM/YYYY", true).isValid() == true ||
            "Data de nascimento inválida",
          (v) =>
            !!this.minorAlert || "Não permitimos cadastros de menores de idade",
          (v) => {
            if (
              moment().diff(
                moment(moment(v, "DD/MM/YYYY")).format("YYYY-MM-DD"),
                "year",
                false
              ) >= 18
            ) {
              this.minorAlert = true;
            }
            return true;
          },
        ],
        phoneNumber: [
          (v) => !!v || "Insira um número de celular",
          (v) =>
            !!/^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/.test(v) ||
            "Número de celular inválido",
        ],
        avatar: [
          (v) => !v || v.size < 30000000 || "Imagem não pode ultrapassar 30 MB",
        ],
        site: [
          (v) =>
            !!/^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm.test(
              v
            ) || "Preencha com um site válido (ex: boutsex.com)",
        ],
      },
    };
  },
  mounted() {
    this.form.avatarUrl = this.currentUser.avatar;
  },
  watch: {
    isAnonymous(val) {
      this.$emit("setAnonymous", val);
    },
    isSpecialist(val) {
      if (val) {
        this.$emit("setAnonymous", false);
      }

      this.$emit("setSpecialist", val);
    },
    showPhoneNumber(val) {
      this.$emit("setShowPhoneNumber", val);
    },
    "form.userName": function (val) {
      this.form.userName = this.form.userName.toLowerCase();
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.userIsAvailable();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
    "form.avatar": function () {
      this.displayErrorMessageOfPicture();
      if (this.form.avatar) {
        this.form.avatarUrl = URL.createObjectURL(this.form.avatar);
      } else {
        this.form.avatarUrl = this.currentUser.avatar;
      }
    },
  },
  methods: {
    async userIsAvailable() {
      if (this.form.userName) {
        UserRepository.userIsAvailable(this.form.userName).then((result) => {
          this.isUserAvailable = result;
          this.$refs.userNameField.validate();
        });
      } else {
        this.isUserAvailable = false;
        this.$refs.userNameField.validate();
      }
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs["uploader"].$refs.input.click();
    },
    validate() {
      if (
        moment().diff(
          moment(moment(this.form.birthDate, "DD/MM/YYYY")).format(
            "YYYY-MM-DD"
          ),
          "year",
          false
        ) < 18
      ) {
        this.$store.dispatch(MINOR, { userId: this.currentUser.id });
        this.minorAlert = false;
      }
      this.$refs.form.validate();
      this.displayErrorMessageOfPicture();
    },

    displayErrorMessageOfPicture() {
      if (this.$refs["uploader"].validations[0]) {
        this.messageAvatarError = this.$refs["uploader"].validations[0];
      } else {
        this.messageAvatarError = "";
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.registration-card {
  padding-bottom: 5rem;
}
.avatar-input {
  opacity: 0;
}
form {
  display: flex;
  justify-content: center;
}
.avatar-input {
  margin: auto;
  justify-content: center;
  text-align: center;
}
</style>