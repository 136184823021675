






























































































































































import moment from "moment";
import Carousel from "./Carousel.vue";
import Vue from "vue";
import { mapGetters } from "vuex";
export default Vue.extend({
  components: { Carousel },
  props: ["media", "voteData"],
  data: () => ({
    playing: false,
    volumeOff: false,
    videoFocused: false,
    isAdmin: false,
    timerInterval: null,
    timeLeft: 0,
  }),
  watch: {
    playing(val) {
      this.playVideo(val);
    },
    volumeOff(val) {
      this.muteVideo(val);
    },
    videoFocused(val) {
      if (!val) {
        this.playing = false;
      }
    },
  },
  mounted() {
    this.checkAdmin();
  },
  computed: {
    ...mapGetters(["currentUser"]),
    getTotalVotes(): number {
      if (this.media.type === "Survey") {
        return this.media.mediaItems.reduce(
          (sum, e) => sum + e.option.votes,
          0
        );
      } else {
        return 0;
      }
    },
    getTimeLeft(): string {
      return moment.utc(this.timeLeft * 1000).format("mm:ss");
    },
    getVideo(): HTMLMediaElement {
      return this.$refs.videoMedia as HTMLMediaElement;
    },
  },

  methods: {
    playVideo(play: Boolean) {
      const video = this.getVideo;
      if (play) {
        video.play();
        this.timerInterval = setInterval(() => {
          const timeLeft = video.duration - video.currentTime;
          this.timeLeft = timeLeft;
        }, 1000);
      } else {
        video.pause();
        clearInterval(this.timerInterval);
      }
    },
    checkAdmin() {
      this.isAdmin = this.currentUser.roles.some((role) => role === "Admin");
    },
    muteVideo(mute: Boolean) {
      const video = this.getVideo;
      if (mute) {
        video.volume = 0;
      } else {
        video.volume = 1;
      }
    },
    fixPercent(val) {
      if (isNaN(val)) {
        return "0%";
      } else {
        return val.toFixed(1) + "%";
      }
    },
    onVideoFocus(entries, observer) {
      this.videoFocused = entries[0].intersectionRatio >= 0.5;
    },
    vote(index) {
      this.$emit("vote", index);
    },
  },
});
