import { CommentRepository } from "@/repositories";
import { User } from "./User";
import firebaseMain from "firebase";

export interface IComment {
  referenceId: string;
  comment: string;
  user: User;
  date: Date;
}

export class Comment implements IComment {
  constructor() {}

  // Este reference ID corresponde a um postId
  referenceId: string = "";
  comment: string = "";
  user: User = new User();
  date: Date = new Date();
  replies: Array<Reply> = [];
  id: string = "";
  deleted: boolean = false;
  ref: firebaseMain.firestore.QueryDocumentSnapshot<
    firebaseMain.firestore.DocumentData
  > = null;

  private isValid: boolean = true;

  static factory = {
    createFromDatabase(comment: any) {
      let newComment = new Comment();

      newComment.referenceId = comment.referenceId;
      newComment.comment = comment.comment;
      newComment.user = comment.user;
      newComment.date = comment.date;
      newComment.replies = comment.replies;
      newComment.id = comment.id;
      newComment.ref = comment.ref;
      newComment.deleted = comment.deleted

      return newComment;
    },

    create(referenceId, comment, user) {
      let newComment = new Comment();

      newComment.referenceId = referenceId;
      newComment.comment = comment;
      newComment.user = user;

      return newComment;
    },
  };

  post() {
    if (this.isValid) {
      return CommentRepository.post(this);
    } else {
      return Promise.reject("asdasd");
    }
  }

  delete() {
    return CommentRepository.delete(this);
  }
}
export class Reply implements IComment {
  constructor() {}

  static factory = {
    createFromDatabase(reply: any) {
      let newReply = new Reply();

      newReply.postId = reply.postId;
      newReply.referenceId = reply.referenceId;
      newReply.comment = reply.comment;
      newReply.date = reply.date;
      newReply.user = reply.user;
      newReply.id = reply.id;
      newReply.deleted = reply.deleted

      return newReply;
    },

    create(postId, referenceId, comment, user) {
      let newReply = new Reply();

      newReply.postId = postId;
      newReply.referenceId = referenceId;
      newReply.comment = comment;
      newReply.user = user;

      return newReply;
    },
  };

  // Este reference ID corresponde a um commentId
  id: string = "";
  postId: string = "";
  referenceId: string = "";
  comment: string = "";
  user: User = new User();
  date: Date = new Date();
  deleted: boolean = false;

  post() {
    return CommentRepository.reply(this);
  }

  delete() {
    return CommentRepository.deleteReply(this);
  }
}
