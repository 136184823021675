



































































import Vue from "vue";
import BottomNavigation from "./views/components/Bottom_Navigation.vue";
import SearchBar from "./views/components/SearchBar.vue";
import * as fb from "@/core/plugins/firebase.plugin";
import { mapGetters } from "vuex";
import Loading from "./views/components/Loading.vue";

export default Vue.extend({
  components: {
    BottomNavigation,
    Loading,
    SearchBar,
  },
  computed: {
    ...mapGetters(["currentUser", "isAnonymous", "isAuthenticated"]),
  },
  data: () => ({
    loading: true,
    alert: true,
  }),
  mounted() {
    fb.auth.onAuthStateChanged(async (user) => {
      this.loading = false;
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
});
