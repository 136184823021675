var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.showCommunityList)?_c('v-btn',{staticClass:"mb-12",attrs:{"fab":"","small":"","fixed":"","bottom":"","right":"","color":"primary","title":"Postar","elevation":"5"},on:{"click":function () { return _vm.$router.push({
          name: 'Novo Social Post',
          params: { group: _vm.getComunityText },
        }); }}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e(),(_vm.showCommunityList)?_c('span',[_c('v-row',[_c('v-card-subtitle',{staticClass:"justify-center ml-2"},[_vm._v(" Comunidades da "),_c('b',[_vm._v("boutSEX")])])],1),_c('v-alert',{attrs:{"color":"amber"}},[_vm._v(" As comunidades estão disponíveis apenas no App. Você ainda pode participar da comunidade geral, mas para a experiência completa com as comunidades boutSEX, "),_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=com.boutsex.app&utm_source=landing_page&utm_campaign=landing_page&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"}},[_vm._v("baixe o App!")])]),(_vm.loadingCommunities)?_c('v-row',{staticClass:"pa-4",attrs:{"no-gutters":"","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('span',[_c('CommunityCard',{attrs:{"community":{
          title: 'Geral',
          description: 'Comunidade geral da boutSEX',
          creatorUser: {
            displayName: 'boutSEX',
            avatar:
              'https://firebasestorage.googleapis.com/v0/b/boutsex-social.appspot.com/o/pictures%2F5MKmaMPYMTfLvumZEFshoVCCqM43%2F126c9a80-c96d-4568-9806-dc29ba69e1b0?alt=media&token=8dac5476-1b88-47c7-a856-c1e70f6024f4',
          },
          members: { members: '1k+' },
        },"enabled":true},nativeOn:{"click":function($event){_vm.showCommunityList = false}}}),_vm._l((_vm.communities),function(community){return _c('CommunityCard',{key:community.id,attrs:{"community":community}})})],2)],1):_c('span',[_c('v-row',[_c('v-card-subtitle',{staticClass:"justify-center ml-2"},[_vm._v(" Publicações no grupo "),_c('b',{domProps:{"textContent":_vm._s(_vm.getComunityText)}})])],1),_vm._l((_vm.posts),function(post,i){return _c('posts',{key:i,attrs:{"post":post,"index":i,"hidePostFollow":post.creatorUser.displayName === 'Anônimo' ||
        post.creatorUser.displayName === _vm.currentUser.displayName},on:{"deletePost":_vm.deletePost}})}),_c('infinite-loading',{on:{"infinite":_vm.infiniteHandler}},[_c('div',{attrs:{"slot":"spinner"},slot:"spinner"},[_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar-three-line, image, article"}})],1),_c('div',{attrs:{"slot":"no-more"},slot:"no-more"},[_vm._v("Sem mais resultados...")]),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"},[_c('v-alert',{attrs:{"color":"grey lighten-2 text-center"}},[_vm._v("Nenhuma publicação recente")])],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }