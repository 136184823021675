import Vue from 'vue'
import Router from 'vue-router';
import { auth } from "@/core/plugins/firebase.plugin";
import store from "@/core/services/store/index.js";

import Login from '@/views/pages/login/Login';
import SignUp from '@/views/pages/login/SignUp';

import Feed from '@/views/pages/feed/Feed.vue'
import Profile from '@/views/pages/profile/Profile.vue';
import NewPost from '@/views/pages/newPost/NewPost.vue';
import Help from './views/pages/help/Help.vue';

import PostDetails from '@/views/pages/post_details/PostDetails.vue';
import EroticTalesDetails from '@/views/pages/erotic_tales_details/EroticTalesDetails.vue';

import Private from '@/views/pages/private/Private.vue'
import ComunityPostDetails from '@/views/pages/comunity_details/ComunityDetails.vue'
import NewComunityPost from '@/views/pages/newComunityPost/NewComunityPost.vue'
import Livestream from '@/views/pages/livestream/Livestream.vue'


Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [{
            path: '/',
            name: 'Home',
            component: Feed,
            meta: {
                showAppBar: true,
                requiresAuth: true,
                onlyAnonymous: false,
                enableSearchBar: true
            }
        },
        {
            path: '/feed',
            name: 'Feed',
            component: Feed,
            meta: {
                showAppBar: false,
                requiresAuth: true,
                onlyAnonymous: false,
                enableSearchBar: true
            }
        },
        {
            path: '/e/:postId',
            name: 'Conto erótico',
            component: EroticTalesDetails,
            meta: {
                requiresAuth: false,
                showAppBar: true,
                onlyAnonymous: false,
            }
        },
        {
            path: '/p/:postId',
            name: 'Postagem',
            component: PostDetails,
            meta: {
                requiresAuth: false,
                showAppBar: true,
                onlyAnonymous: false,
            }
        },
        {
            path: '/g/:comunityId',
            name: 'Papo privado',
            component: ComunityPostDetails,
            meta: {
                requiresAuth: false,
                showAppBar: true,
                onlyAnonymous: false,
            }
        },
        {
            path: '/live/:liveId',
            name: 'Live',
            component: Livestream,
            meta: {
                requiresAuth: false,
                showAppBar: true,
                onlyAnonymous: false,
            }
        },
        {
            path: '/novo-comunity-post',
            name: 'Novo Social Post',
            component: NewComunityPost,
            meta: {
                requiresAuth: true,
                showAppBar: true,
                onlyAnonymous: false,
            }
        },
        {
            path: '/novo-post',
            name: 'Novo Sexpert Post',
            component: NewPost,
            meta: {
                requiresAuth: true,
                showAppBar: true,
                onlyAnonymous: false,
            }
        },

        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/registrar',
            name: 'SignUp',
            component: SignUp
        },
        {
            path: '/ajuda/',
            name: 'Ajuda',
            component: Help,
            meta: {
                showAppBar: true,
                requiresAuth: true,
                onlyAnonymous: false,
            }
        },
        {
            path: '/private/',
            name: 'Social',
            component: Private,
            meta: {
                showAppBar: true,
                requiresAuth: true,
                onlyAnonymous: false,
            }
        },
        //Aviso: Essa rota por te conflito com as outras ela deve ser a ultima do array.
        {
            path: '/:userName',
            name: 'Profile',
            component: Profile,
            meta: {
                showAppBar: true,
                requiresAuth: true,
                onlyAnonymous: false,
            },
            beforeEnter: (to, from, next) => {
                //TODO: Verificar aqui se o usuario existe

                next()
            }
        },
    ]
});

let anonymousBefore = store.getters.currentUser.anonymous;

router.beforeEach((to, from, next) => {

    if (from.meta.onlyAnonymous) {
        store.dispatch("setAnonymous", anonymousBefore)
    }

    const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
    if (requiresAuth && !auth.currentUser) {

        next({ name: 'Login' })
    } else {
        if (to.meta.onlyAnonymous) {
            anonymousBefore = store.getters.currentUser.anonymous;
            store.dispatch("setAnonymous", true)
        }
        next()
    }
});





export default router;