<template>
  <v-form ref="form" v-model="isValid" lazy-validation @submit.prevent="save">
    <v-card flat width="300" class="registration-card">
            <v-card-text class="mb-4">
        <h2 class="text-center">Por favor, preencha os dados faltantes de seu cadastro</h2>
      </v-card-text>
      <v-combobox outlined dense :items="genders" label="Gênero" v-model="form.gender" :rules="rules.gender"></v-combobox>
      <v-combobox outlined dense :items="sexualOrientations" v-model="form.sexualOrientation" :rules="rules.sexualOrientation" label="Orientação sexual"></v-combobox>
    </v-card>
  </v-form>
</template>

<script>
import { UserRepository } from "@/repositories";
import moment from "moment";
import Vue from "vue";
import { MINOR } from "@/core/services/store/analytics.module";

export default Vue.extend({
  props: ["backStepActive", "saveButtonText", "backButtonText", "currentUser"],
  computed: {
    formattedDate() {
      return this.form.birthDate
        ? moment(this.form.birthDate).format("DD/MM/YYYY")
        : "";
    },
    today() {
      return moment().format();
    },
    userNameRule() {
      return [
        (v) => !!v || "Preencha seu nome de usuário",
        (v) =>
          !!/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/gim.test(v) ||
          "Seu nome de usuário não pode conter espaços, precisa começar e terminar com uma letra ou número",
        (v) => !!this.isUserAvailable || "Esse nome de usuário já está em uso",
      ];
    },
  },
  data() {
    return {
      minorAlert: true,
      isValid: false,
      isSelecting: false,
      modal: false,
      isSpecialist: false,
      showPhoneNumber: false,
      isAnonymous: false,
      messageAvatarError: "",
      isUserAvailable: true,
      awaitingSearch: false,
      genders: [
        "Feminino",
        "Masculino",
        "Trans homem",
        "Trans mulher",
        "Travesti",
        "Transgênero",
        "Pessoa trans",
        "Neutro",
        "Sem gênero",
        "Cross gender",
        "Outra",
      ],
      sexualOrientations: [
        "Heterossexual",
        "Homossexual",
        "Bissexual",
        "Outra",
      ],
      form: {
        avatar: null,
        avatarUrl: "",
        userName: "",
        description: "",
        site: "",
        birthDate: null,
        phoneNumber: "",
        gender: "",
        sexualOrientation: "",
      },
      rules: {
        gender: [
          (v) => !!v || "Preencha o seu gênero",
        ],
        sexualOrientation: [
          (v) => !!v || "Preencha sua orientação sexual",
        ],
        phoneNumber: [
          (v) => !!v || "Insira um número de celular",
          (v) =>
            !!/^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/.test(v) ||
            "Número de celular inválido",
        ],
        avatar: [
          (v) => !v || v.size < 30000000 || "Imagem não pode ultrapassar 30 MB",
        ],
        site: [
          (v) =>
            !!/^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm.test(
              v
            ) || "Preencha com um site válido (ex: boutsex.com)",
        ],
      },
    };
  },
  mounted() {
    this.form.avatarUrl = this.currentUser.avatar;
  },
  watch: {
    isAnonymous(val) {
      this.$emit("setAnonymous", val);
    },
    isSpecialist(val) {
      if (val) {
        this.$emit("setAnonymous", false);
      }

      this.$emit("setSpecialist", val);
    },
    showPhoneNumber(val) {
      this.$emit("setShowPhoneNumber", val);
    },
    "form.userName": function (val) {
      this.form.userName = this.form.userName.toLowerCase();
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.userIsAvailable();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
    "form.avatar": function () {
      this.displayErrorMessageOfPicture();
      if (this.form.avatar) {
        this.form.avatarUrl = URL.createObjectURL(this.form.avatar);
      } else {
        this.form.avatarUrl = this.currentUser.avatar;
      }
    },
  },
  methods: {
    async userIsAvailable() {
      if (this.form.userName) {
        UserRepository.userIsAvailable(this.form.userName).then((result) => {
          this.isUserAvailable = result;
          this.$refs.userNameField.validate();
        });
      } else {
        this.isUserAvailable = false;
        this.$refs.userNameField.validate();
      }
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs["uploader"].$refs.input.click();
    },
    validate() {
      this.$refs.form.validate();
    },

    displayErrorMessageOfPicture() {
      if (this.$refs["uploader"].validations[0]) {
        this.messageAvatarError = this.$refs["uploader"].validations[0];
      } else {
        this.messageAvatarError = "";
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.registration-card {
  padding-bottom: 5rem;
}
.avatar-input {
  opacity: 0;
}
form {
  display: flex;
  justify-content: center;
}
.avatar-input {
  margin: auto;
  justify-content: center;
  text-align: center;
}
</style>