



































































import WelcomeWizard from "@/views/pages/wizard/WelcomeWizard.vue";
import Posts from "../../components/Post.vue";
import {
  PostRepository,
  UserRepository,
  LivestreamRepository,
} from "@/repositories";
import { Post } from "@/models/Post";
import Vue from "vue";
import { ProfileVModel } from "@/models/User";
import { mapGetters } from "vuex";
import CompleteGenderWizard from "../wizard/CompleteGenderWizard.vue";
import SexpertsCarousel from "@/views/components/Sexperts_Carousel.vue";
import LivesCarousel from "@/views/components/LivesCarousel.vue";
import { Livestream } from "@/models/Livestream";

export default Vue.extend({
  components: {
    Posts,
    WelcomeWizard,
    CompleteGenderWizard,
    SexpertsCarousel,
    LivesCarousel,
  },
  data: () => ({
    isSpecialist: false,

    posts: new Array<Post>(),
    livestreams: new Array<Livestream>(),
    specialists: new Array<ProfileVModel>(),
  }),
  computed: {
    ...mapGetters(["currentUser"]),
  },
  mounted() {
    this.checkSexpert();
    LivestreamRepository.getLivestreams().then((data) => {
      this.livestreams = data;
    });
    UserRepository.getSpecialists().then((result) => {
      this.specialists = result;
    });
  },
  methods: {
    checkSexpert() {
      this.isSpecialist = this.currentUser.roles.some(
        (role) => role === "Specialist"
      );
    },
    infiniteHandler($state) {
      let lastVisible = null;

      if (this.posts.length > 0) {
        lastVisible = this.posts[this.posts.length - 1].postRef;
      }

      PostRepository.getPosts(lastVisible).then((r: Array<Post>) => {
        if (r.length > 0) {
          this.posts.push(...r);
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },

    deletePost(index: number) {
      this.posts.splice(index, 1);
    },
  },
});
