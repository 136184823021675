<template>
  <span>
    <v-slide-group v-model="model" class="pa-4" v-if="livestreams.length > 0">
      <v-slide-item
        v-for="livestream in livestreams"
        :key="livestream.data.liveId"
      >
        <v-col class="ma-1" height="200" width="300">
          <v-card
            color="black"
            class="thumbnail-image"
            :img="livestream.thumbnail"
            height="200"
            width="300"
            @click="$router.push(`/live/${livestream.data.liveId}`)"
          >
            <v-card-title>
              <v-chip color="primary" class="ml-auto" x-small>AO VIVO</v-chip>
            </v-card-title>
          </v-card>
          <v-list-item class="live-details">
            <v-list-item-avatar color="red">
              <v-img :src="livestream.creatorUser.avatar"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                v-text="livestream.creatorUser.displayName"
              ></v-list-item-title>
              <v-list-item-subtitle v-text="livestream.title">
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-slide-item>
    </v-slide-group>
    <v-card-subtitle v-else class="ml-10 mr-6"
      >Nenhuma transmissão disponível no momento</v-card-subtitle
    >
  </span>
</template>

<script>
export default {
  props: ["livestreams"],
  data: () => ({ model: null }),
};
</script>

<style >
.live-details {
  width: 300px !important;
  max-width: 300px !important;
}
</style>