import { Counters } from "./Comunity"
import { UserVModel } from "./Comunity"

export class CommunityGroup {
    constructor() { }
    private _id: string = ""
    public get id(): string {
        return this._id
    }

    private _title: string = ""
    public get title(): string {
        return this._title
    }

    private _description: string = ""
    public get description(): string {
        return this._description
    }

    private _thumbnail: string = ""
    public get thumbnail(): string {
        return this._thumbnail
    }

    private _creatorUser: UserVModel = new UserVModel()
    public get creatorUser(): UserVModel {
        return this._creatorUser
    }

    private _createdDate: Date = new Date();
    public get createdDate(): Date {
        return this._createdDate
    }

    private _members: number = 0
    public get members(): number {
        return this._members
    }

    private _deleted: boolean = false
    public get deleted(): boolean {
        return this._deleted
    }

    static factory = {
        create(communityGroup: any) {
            let newCommunityGroup = new CommunityGroup();

            newCommunityGroup._id = communityGroup.id
            newCommunityGroup._title = communityGroup.title
            newCommunityGroup._members = communityGroup.counters
            newCommunityGroup._createdDate = communityGroup.createdDate
            newCommunityGroup._thumbnail = communityGroup.thumbnail
            newCommunityGroup._description = communityGroup.description
            newCommunityGroup._creatorUser = communityGroup.creatorUser
            newCommunityGroup._deleted = communityGroup.deleted

            return newCommunityGroup;
        },
        createFromDB(communityGroup: firebase.default.firestore.DocumentData, communityGroupId: string) {
            let newCommunityGroup = new CommunityGroup();

            newCommunityGroup._id = communityGroupId
            newCommunityGroup._title = communityGroup.title
            newCommunityGroup._members = communityGroup.counters
            newCommunityGroup._createdDate = communityGroup.createdDate
            newCommunityGroup._thumbnail = communityGroup.thumbnail
            newCommunityGroup._description = communityGroup.description
            newCommunityGroup._creatorUser = communityGroup.creatorUser
            newCommunityGroup._deleted = communityGroup.deleted

            return newCommunityGroup;
        },
    }
}