











































import Vue from "vue";
import moment from "moment";
moment.locale("pt-br");

export default Vue.extend({
  props: {
    creatorUser: Object,
    createdDate: [Object, Date],
    previewMode: Boolean,
    hideUserFollow: Boolean,
  },
  computed: {
    getCreatedDate() {
      const fireBaseTime = new Date(
        this.createdDate.seconds * 1000 + this.createdDate.nanoseconds / 1000000
      );
      const momentDate = moment(new Date(fireBaseTime)).fromNow();
      return momentDate === "Data inválida" ? "Agora" : momentDate;
    },
  },
  methods: {
    goToProfile(userName) {
      if (userName) {
        this.$router.push({ name: "Profile", params: { userName: userName } });
      }
    },
  },
});
