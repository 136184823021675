var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"color":"primary","fixed":"","dark":"","app":"","dense":"","hide-on-scroll":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-magnify")]),_c('v-text-field',{ref:"searchInput",attrs:{"label":"Buscar","hide-details":"","filled":"","dense":"","outlined":"","clearable":""},on:{"input":function($event){return _vm.scrollToTop()}},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),(_vm.searchQuery)?_c('v-main',{staticClass:"pb-1"},[_c('ais-instant-search',{attrs:{"search-client":_vm.searchClient,"index-name":"posts"}},[_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var query = ref.query;
var hits = ref.hits;
return _c('div',{},[(hits.length > 0 && query)?_c('ais-hits',[_c('div',{staticClass:"text-center caption mt-4"},[_vm._v(" Exibindo resultados para: "),_c('i',{domProps:{"textContent":_vm._s(query)}})])]):_vm._e()],1)}}],null,false,2379107723)}),_c('ais-search-box',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"value":_vm.searchQuery}}),_c('v-list',{attrs:{"light":""}},[_c('v-list-item-group',[_c('ais-hits',{staticClass:"list-item",scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return _c('v-list-item',{staticClass:"list-item",attrs:{"light":"","to":("/p/" + (item.objectID)),"three-line":""}},[_c('v-list-item-avatar',{attrs:{"color":"primary"}},[_c('v-img',{attrs:{"src":item['creatorUser.avatar']}})],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_c('span',{staticClass:"caption",domProps:{"textContent":_vm._s('@' + item['creatorUser.userName'])}})]),_c('v-list-item-title',[_c('div',{staticClass:"hit-title"},[_c('ais-highlight',{attrs:{"attribute":"title","hit":item}})],1)]),_c('v-list-item-subtitle',[_c('div',{staticClass:"hit-content"},[_c('ais-highlight',{attrs:{"attribute":"content","hit":item}})],1)])],1)],1)}}],null,false,1856013002)})],1)],1),_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hits = ref.hits;
return _c('div',{},[(hits.length > 0)?_c('ais-hits',[_c('v-alert',{staticClass:"text-center caption ma-4",attrs:{"outlined":"","dense":"","color":"primary"}},[_vm._v(" Fim dos resultados... voltando para o feed... ")])],1):_vm._e()],1)}}],null,false,3173876090)}),_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var query = ref.query;
var hits = ref.hits;
return _c('div',{},[(hits.length < 1)?_c('ais-hits',[_c('v-alert',{staticClass:"text-center caption ma-4",attrs:{"outlined":"","dense":"","color":"primary"}},[_vm._v(" Nenhum resultado para: "),_c('i',[_vm._v(_vm._s(query))]),_vm._v(". ")])],1):_vm._e()],1)}}],null,false,1736676134)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }