import * as fb from "@/core/plugins/firebase.plugin";
import { baseRepository } from "./Base.repository";
import firebaseMain from "firebase";
import { Livestream } from "@/models/Livestream";
import { User } from "@/models/User";

export class livestreamRepository extends baseRepository {
    constructor() {
        super();
    }

    private getLivesMethodFromFirebase(): firebaseMain.firestore.Query<firebaseMain.firestore.DocumentData> {
        return fb.db
            .collection("lives")
            .orderBy("createdDate", "desc");

    }

    getLivestreams(): Promise<Array<Livestream>> {
        return this.getLivesMethodFromFirebase()
            .where("deleted", "==", false)
            .where("status", "==", 'active')
            .get()
            .then((doc) => {
                let livestreams = new Array<Livestream>();
                const user = this.getUserRef();

                doc.forEach((snap) => {
                    let live = Livestream.factory.createFromDB(snap.data(), snap.id);

                    livestreams.push(live);
                });

                return livestreams;
            });
    }
    watchLivestream(liveId?: string, onUpdate?: Function) {
        return fb.db
            .collection("lives")
            .doc(liveId)
            .onSnapshot((querySnapshot) => {

                onUpdate(querySnapshot);

            });
    }
    closeWatchLivestream(liveId?: string) {
        const unsubscribe = fb.db
            .collection("lives")
            .doc(liveId)

            .onSnapshot(() => {


            });
        unsubscribe()
    }

    syncUserStatus(userId: string, liveId: string, playerIsPlaying: boolean) {
        const userStatusDatabaseRef = firebaseMain.database().ref('/status/' + userId);

        const isOfflineForDatabase = {
            isPlaying: playerIsPlaying,
            liveId: liveId,
            date: firebaseMain.database.ServerValue.TIMESTAMP,
            status: 'offline'
        };

        const isOnlineForDatabase = {
            isPlaying: playerIsPlaying,
            liveId: liveId,
            date: firebaseMain.database.ServerValue.TIMESTAMP,
            status: 'online'
        };


        firebaseMain.database().ref('.info/connected').on('value', (snapshot) => {

            if (snapshot.val() == false) {
                return;
            };

            userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(() => {

                userStatusDatabaseRef.set(isOnlineForDatabase);
            });
        });
    }

    updateUserOnlineInLive(liveId?: string, userId?: string, isOnline?: boolean) {
        const batch = fb.db.batch();

        batch.update(
            fb.db.collection('lives').doc(liveId).collection("viewers").doc(userId),
            { "online": isOnline });

        if (isOnline) {
            batch.update(fb.db.collection('lives').doc(liveId),
                { "metrics.online": firebaseMain.firestore.FieldValue.increment(1) });


        } else {
            batch.update(fb.db.collection('lives').doc(liveId),
                { "metrics.online": firebaseMain.firestore.FieldValue.increment(-1) });


        }

        return batch.commit();
    }

    updateUserEventStream(
        liveId: string, userId: string, eventName: string) {
        const displayEvent =
            { date: new Date(), name: eventName }
            ;

        return fb.db.collection('lives')
            .doc(liveId)
            .collection("viewers")
            .doc(userId)
            .set({ "events": firebaseMain.firestore.FieldValue.arrayUnion(displayEvent) }, { merge: true });
    }


}