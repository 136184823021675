









































































































import LivePlayer from "../../components/LivePlayer.vue";

import Vue from "vue";

import { Livestream } from "@/models/Livestream";
import { LiveChatRepository, LivestreamRepository } from "@/repositories";
import { LiveChat } from "@/models/LiveChat";
import { mapGetters } from "vuex";
export default Vue.extend({
  components: {
    LivePlayer,
  },
  async mounted() {
    if (!this.isAuthenticated) {
      this.$router.push("/login");
    }
    this.isAdmin = this.currentUser.roles.some((role) => role === "Admin");
    this.chatMessages = [];
    LiveChatRepository.watchLiveStreamChats(
      this.getLiveId,

      this.updateMessages
    );

    LivestreamRepository.watchLivestream(this.getLiveId, this.updateLivestream);
  },

  data: () => ({
    livestream: new Livestream(),
    chatMessages: new Array<LiveChat>(),
    active: false,
    chatMessage: "",
    copyMessage: false,
    explicitEnabled: false,
    isAdmin: false,
    scrollNewMessages: false,
  }),
  watch: {
    currentUser() {
      this.isAdmin = this.currentUser.roles.some((role) => role === "Admin");
    },
  },
  computed: {
    ...mapGetters(["currentUser", "isAuthenticated"]),

    getChatWrapper(): HTMLElement {
      return document.querySelector(".chat-messages");
    },
    getLiveId(): string {
      return this.$route.params.liveId;
    },
    getStatusMessage(): string {
      switch (this.livestream.status) {
        case "idle":
          return "Esta transmissão está inativa";
        case "active":
          return "Carregando transmissão...";
        case "completed":
          return "Esta transmissão acabou. Mas não se preocupe. Em breve este conteúdo será publicado em nosso feed!";

        default:
          return "Esta transmissão está inativa";
      }
    },
  },

  methods: {
    // infiniteHandler($state) {
    // let lastVisible = null;
    // if (this.chatMessages.length > 0) {
    //   lastVisible = this.chatMessages[this.chatMessages.length - 1].id;
    // }
    // PostRepository.getPosts(lastVisible).then((r: Array<Post>) => {
    //   if (r.length > 0) {
    //     this.posts.push(...r);
    //     $state.loaded();
    //   } else {
    //     $state.complete();
    //   }
    // });
    // },
    publishChat() {
      if (this.chatMessage != "") {
        let liveChat = LiveChat.factory.create(
          this.getLiveId,
          this.chatMessage,
          this.currentUser
        );
        liveChat.publish().then((docRef) => {
          liveChat.id = docRef;
        });
        this.chatMessage = "";
      }
    },
    scrollChatToBottom() {
      this.getChatWrapper.scrollTop = this.getChatWrapper.scrollHeight;
      this.scrollNewMessages = false;
    },
    scrollChat() {
      this.$nextTick(() => {
        if (
          this.getChatWrapper.scrollTop + 900 >
            this.getChatWrapper.scrollHeight ||
          this.getChatWrapper.scrollTop == 0
        ) {
          this.getChatWrapper.scrollTop = this.getChatWrapper.scrollHeight;
          this.scrollNewMessages = false;
        } else {
          this.scrollNewMessages = true;
        }
      });
    },
    updateMessages(chatMessagesFromFirebase) {
      let liveChats: Array<LiveChat> = [];
      chatMessagesFromFirebase.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;

        let chatMessageData = LiveChat.factory.createFromDB(data, doc.id);
        liveChats.push(chatMessageData);
      });
      this.chatMessages = liveChats;
      this.scrollChat();
    },

    updateLivestream(snapshotFromFirebase) {
      this.active = snapshotFromFirebase.data().status == "active";
      this.livestream = snapshotFromFirebase.data();
    },
    async sharePost() {
      let refoward = "/live/";

      const shareData = {
        title: "Assista à live da boutSEX",
        text: this.livestream.title,
        url:
          window.location.protocol +
          "//" +
          window.location.host +
          refoward +
          this.livestream.data.liveId,
      };

      await navigator.share(shareData);
      // this.$store.dispatch(SHAREPOSTMENU, this.post.id);
    },
    deleteChatMessage(chatMessage: LiveChat) {
      chatMessage.delete().then(() => {});
    },
    shareCopyLink() {
      let refoward = "/live/";

      const el = document.createElement("textarea");
      el.value =
        window.location.protocol +
        "//" +
        window.location.host +
        refoward +
        this.livestream.data.liveId;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.copyMessage = true;
      // this.$store.dispatch(SHAREPOSTCOPYLINK, this.post.id);
    },
  },
  beforeRouteLeave: function (to, from, next) {
    LiveChatRepository.closeLiveStreamChats(this.getLiveId);
    LivestreamRepository.closeWatchLivestream(this.getLiveId);

    next();
  },
});
