<template>
  <v-main class="pa-0">
    <v-card flat width="300" class="mx-auto text-center">
      <v-img
        :src="imgUrl"
        width="150"
        max-height="150"
        class="mx-auto"
        height="150"
      ></v-img>

      <v-card-text>
        <h2 v-text="title"></h2>
      </v-card-text>

      <v-card-text>
        <div v-text="text"></div>
      </v-card-text>
    </v-card>
  </v-main>
</template>

<script>
export default {
  props: ["title", "text", "imgUrl"],
  data() {
    return {};
  },
  methods: {
    next() {
      this.$emit("next");
    },
    previous() {
      this.$emit("previous");
    },
  },
};
</script>

<style>
</style>