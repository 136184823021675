
















































































































import WelcomeWizard from "@/views/pages/wizard/WelcomeWizard.vue";
import Posts from "../../components/ComunityPost.vue";
import Question from "../../components/Question.vue";
import Tweet from "../../components/Tweet.vue";
import {
  ComunityRepository,
  CommunityGroupRepository,
} from "../../../repositories";
import { Comunity } from "@/models/Comunity";
import Vue from "vue";
import { ProfileVModel } from "@/models/User";
import { mapGetters } from "vuex";
import CommunityCard from "../../components/Community_Card.vue";
import { CommunityGroup } from "../../../models/CommunityGroup";

export default Vue.extend({
  components: { Posts, Question, Tweet, WelcomeWizard, CommunityCard },
  data: () => ({
    model: null,
    showCommunityList: true,
    loadingCommunities: true,
    communities: new Array<CommunityGroup>(),
    posts: new Array<Comunity>(),
    specialists: new Array<ProfileVModel>(),
  }),
  computed: {
    ...mapGetters(["currentUser"]),
    getComunityText() {
      const comunityGroup = this.$route.params.group;

      if (comunityGroup == undefined) {
        return "Geral";
      }
      return comunityGroup;
    },
  },

  mounted() {
    CommunityGroupRepository.getCommunityGroupsList().then(
      (r: Array<CommunityGroup>) => {
        this.communities.push(...r);
        this.loadingCommunities = false;
      }
    );
  },
  methods: {
    infiniteHandler($state) {
      let lastVisible = null;

      if (this.posts.length > 0) {
        lastVisible = this.posts[this.posts.length - 1].postRef;
      }

      ComunityRepository.getPosts(this.getComunityText, lastVisible).then(
        (r: Array<Comunity>) => {
          if (r.length > 0) {
            this.posts.push(...r);
            $state.loaded();
          } else {
            $state.complete();
          }
        }
      );
    },
    goToProfile(userName) {
      if (userName) {
        this.$router.push({ name: "Profile", params: { userName: userName } });
      }
    },
    deletePost(index: number) {
      this.posts.splice(index, 1);
    },
  },
});
