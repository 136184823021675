import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/storage"
import { environment } from "@/core/config/environment";

firebase.initializeApp(environment.firebase);

firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
});

firebase
  .firestore()
  .enablePersistence({
    experimentalForceOwningTab: false,
    synchronizeTabs: true,
  })
  .catch((err) => {
    console.error("Firebase Persistence", err);
    if (err.code == "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

firebase.storage().refFromURL(`gs://${environment.firebase.storageBucket}`)


firebase
  .auth()
  .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  .catch((error) => {
    console.error("Firebase  Auth", error);
  });

// utils
const storage = firebase.storage();
const db = firebase.firestore();
const auth = firebase.auth();
const analytics = firebase.analytics();

const user = {
  currentUser() {
    return auth.currentUser;
  },

  currentUserRef() {
    return db.collection("users").doc(auth.currentUser?.uid);
  }
}

export { db, auth, analytics, user, storage };