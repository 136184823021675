import { commentRepository } from "./Comment.repository";
import { postRepository } from "./PostRepository";
import { userRepository } from "./UserRepository"
import { comunityRepository } from "./Comunity.repository"
import { communityGroupRepository } from "./CommunityGroup.repository"
import { commentComunityRepository } from "./CommentComunity.repository"
import { livestreamRepository } from "./Livestream.repository"
import { liveChatRepository } from "./LiveChat.repository"
import { eroticTalesRepository } from "./EroticTalesRepository"
import { commentEroticTalesRepository } from "./CommentEroticTales.repository"


const UserRepository = new userRepository();
const PostRepository = new postRepository();
const CommentRepository = new commentRepository();
const ComunityRepository = new comunityRepository();
const CommunityGroupRepository = new communityGroupRepository();
const CommentComunityRepository = new commentComunityRepository();
const LivestreamRepository = new livestreamRepository();
const LiveChatRepository = new liveChatRepository();
const EroticTalesRepository = new eroticTalesRepository();
const CommentEroticTalesRepository = new commentEroticTalesRepository();

export {
    UserRepository,
    PostRepository,
    CommentRepository,
    ComunityRepository,
    CommentComunityRepository,
    LivestreamRepository,
    LiveChatRepository,
    CommunityGroupRepository,
    EroticTalesRepository,
    CommentEroticTalesRepository
}