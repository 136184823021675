































































































































































































































































import { CommentComunity, ReplyComunity } from "@/models/CommentComunity";
import { CommentComunityRepository } from "@/repositories";
import moment from "moment";
import Vue from "vue";
import { mapGetters } from "vuex";
import { COMMENT, REPLY } from "../../core/services/store/analytics.module";
import { UNLOGGED_OPEN_COMMENT } from "@/core/services/store/analytics.module";

export default Vue.extend({
  props: {
    postTitle: String,
    postId: String,
  },

  data: () => ({
    loadingCommentaries: false,
    loadingComment: false,
    isSpecialist: false,
    commentText: "",
    commentaries: new Array<CommentComunity>(),
    sheet: false,
    replyUser: "",
    commentId: "",
  }),
  computed: {
    ...mapGetters(["currentUser", "isAuthenticated", "isAnonymous"]),
  },
  watch: {
    sheet() {
      if (this.sheet) {
      }
    },
    currentUser() {
      if (this.isAuthenticated) {
        this.isSpecialist = this.currentUser.roles.some(
          (role) => role === "Specialist"
        );
      }
    },
  },
  mounted() {
    if (!this.isAuthenticated) {
      this.$store.dispatch(UNLOGGED_OPEN_COMMENT, this.postId);
    }
    this.isSpecialist = this.currentUser.roles.some(
      (role) => role === "Specialist"
    );
  },
  methods: {
    toggleAnonymous() {
      if (!this.$route.meta.onlyAnonymous) {
        this.$store
          .dispatch("toggleAnonymous")
          .then(() => {})
          .catch((err) => {
            alert(err);
          });
      }
    },
    infiniteHandler($state) {
      let lastVisible = null;

      if (this.commentaries.length > 0) {
        lastVisible = this.commentaries[this.commentaries.length - 1].ref;
      }

      CommentComunityRepository.getComments(this.postId, lastVisible)
        .then((result: Array<CommentComunity>) => {
          if (result.length > 0) {
            this.commentaries.push(...result);
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .finally(() => {
          this.loadingCommentaries = false;
        });
    },
    deleteComment(index) {
      this.commentaries[index].delete().then(() => {
        this.$emit(
          "decreaseComment",
          1 + this.commentaries[index].replies.length
        );
        this.commentaries.splice(index, 1);
      });
    },
    deleteReply(commentIndex, replyIndex) {
      this.commentaries[commentIndex].replies[replyIndex].delete().then(() => {
        this.$emit("decreaseComment", 1);
        this.commentaries[commentIndex].replies.splice(replyIndex, 1);
      });
    },
    goToProfile(userName) {
      if (userName !== "Anônimo") {
        this.$router.push({ name: "Profile", params: { userName: userName } });
      }
    },
    getCreatedDate(val) {
      const fireBaseTime = new Date(
        val.seconds * 1000 + val.nanoseconds / 1000000
      );
      const momentDate = moment(new Date(fireBaseTime)).fromNow();
      return momentDate === "Data inválida" ? "Agora" : momentDate;
    },
    comment() {
      this.loadingComment = true;
      if (this.commentText) {
        let comment = CommentComunity.factory.create(
          this.postId,
          this.commentText,
          this.currentUser
        );

        comment
          .post()
          .then((commentRef : any) => {
            this.$emit("increaseComment");
            this.$store.dispatch(COMMENT, { userId: this.currentUser.id });
            comment.id = commentRef.id;
            this.commentaries.unshift(comment);
          })
          .catch((err) => console.log(err))
          .finally(() => {
            this.loadingComment = false;
          });

        this.commentText = "";
      }
    },
    reply() {
      if (this.commentText) {
        this.loadingComment = true;
        let reply = ReplyComunity.factory.create(
          this.postId,
          this.commentId,
          this.commentText,
          this.currentUser
        );
        reply
          .post()
          .then((replyRef: any) => {
            this.$emit("increaseComment");
            this.$store.dispatch(REPLY, { userId: this.currentUser.id });
            reply.id = replyRef.id;
          })
          .finally(() => {
            const selectedComment = this.commentaries.find(
              (c) => c.id == this.commentId
            );
            if (selectedComment !== undefined) {
              selectedComment.replies.unshift(reply);
            }
            this.loadingComment = false;
            this.removeReply();
          });
      }
    },
    replyChange(creatorUser, commentId) {
      this.commentId = commentId;
      this.replyUser = creatorUser;
      const reply = this.$refs.commentField as HTMLElement;
      reply.focus();
    },
    removeReply() {
      this.replyUser = "";
      this.commentText = "";
      this.commentId = "";
    },
  },
});
